.popup {
    display: none;
    padding: 1rem;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 40;

    background-color: var(--color-gray-dark-40);

    &.-visible {
        display: flex;
    }
}

.popup__inner {
    max-width: 54rem;
    min-width: 25rem;
    width: 100%;
    max-height: 90%;
    margin: auto;

    overflow-y: auto;

    background-color: var(--color-off-white);
    border-radius: 1rem;

    position: relative;

    .popup.-has-content &, .popup.-has-error & {
        min-height: 0;
    }
}

.popup__tabs {
    display: flex;
    height: 6rem;
    border-bottom: .1rem solid var(--color-borders);
    border-radius: .5rem .5rem 0 0;
    margin-bottom: 2.3rem;
}

.popup__tab {
    padding: 1.9rem 3.5rem;
    border-right: .1rem solid var(--color-borders);
    font-size: 1.6rem;
    color: var(--color-text);

    &.-active {
        height: 6.1rem;
        border-bottom: none;
        background-color: var(--color-white);
        color: var(--color-primary);
    }
}

.popup__content, .popup__error {
    display: none;
    width: 100%;
    padding: 2.5rem 3.5rem 3.8rem;
    box-sizing: border-box;

    overflow-x: hidden;
    overflow-y: auto;

    &.-left {
        text-align: left;
    }

    &.-center {
        text-align: center;
    }

    .form__intro {
        margin-bottom: 2.3rem;
    }

    .form__buttons {
        margin-top: 1.8rem;
    }

    .form__actions  {
        margin: 3.7rem 0 0;
    }
}

.popup.-has-content .popup__content {
    display: block;
}

.popup.-has-error .popup__error {
    display: block;
}

.popup__title {
    font-size: 2.8rem;
    text-align: center;
    margin: 0;

    &.title-4 {
        font-size: 2.4rem;
    }

    .popup__content.-left & {
        text-align: left;
    }
}

.popup__buttons {
    width: 100%;
    padding: 0 3.5rem 3.8rem;
    box-sizing: border-box;

    .button:not(:last-of-type) {
        margin: 0 2.5rem 1rem 0;
    }

    form {
        margin: 0;
    }
}
