button {
    background: none;
    border: none;
    outline: none;
    font-size: 1.6rem;
}

.button {
    display: inline-block;
    margin: 0;
    padding: 1.4rem 2rem;
    background-color: var(--color-primary);
    border-radius: .4rem;
    color: var(--color-white);
    font-family: var(--font-family);
    font-size: 1.6rem;
    text-decoration: none;
    text-align: center;
    line-height: 1.38;
    letter-spacing: .05rem;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color .3s ease;

    &.-medium {
        padding: .8rem 2rem;
    }

    &.-full-width {
        width: 100%;
    }

    svg {
        @include svg;
        pointer-events: none;
        fill: var(--color-white);
    }

    &:hover, &:focus {
        background-color: var(--color-primary-hover);
        color: var(--color-white);
    }

    &.-gray {
        background-color: var(--color-off-white);
        color: var(--color-text);

        svg {
            fill: var(--color-text);
        }

        &:hover, &:focus {
            background-color: var(--color-gray-button);
        }
    }

    &.-red {
        background-color: var(--color-danger);
        color: var(--color-white);

        &:hover, &:focus {
            background-color: var(--color-danger-hover);
            color: var(--color-white);
        }
    }

    &.-white {
        background-color: var(--color-white);
        color: var(--color-gray-dark);

        svg {
            fill: var(--color-gray-dark);
        }
    }

    &.-green,
    &.-active {
        background-color: var(--color-success);
        color: var(--color-white);

        svg {
            fill: var(--color-white);
        }

        &:hover, &:focus {
            background-color: var(--color-orange);
        }
    }

    &.-social-login {
        background-color: var(--color-white);
        border: .1rem solid var(--color-gray-dark);
        color: var(--color-gray-dark);

        font-size: 1.3rem;
        padding: 1.2rem 1.7rem;
        height: 4rem;

        svg {
            fill: var(--color-gray-dark);
        }

        &:hover, &:focus {
            background-color: var(--color-gray-dark);
            color: var(--color-white);

            svg {
                fill: var(--color-white);
            }
        }
    }

    &.-outline {
        background-color: var(--color-white);
        border: .1rem solid var(--color-primary);
        color: var(--color-primary);

        &:hover, &:focus {
            border-color: var(--color-primary-hover);
            color: var(--color-primary-hover);
        }
    }

    &.-search {
        &::before {
            content: '';
            display: inline-block;
            margin-right: 1rem;
            background-image: url("../icons/search.svg");
            background-size: 1.4rem 1.4rem;
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    &.-view,
    &.-edit,
    &.-bin {
        background-color: var(--color-gray-button);
        border-radius: .5rem;
        width: 3.2rem;
        height: 3.2rem;
        padding: .7rem;

        &::before {
            transition: background .3s ease;
        }

        &:hover, &:focus {
            @supports (mask-repeat: no-repeat) {
                &::before {
                    background: var(--color-primary);
                }
            }
        }
    }

    &.-view {
        &::before {
            content: '';
            display: inline-block;
            background-image: url("../icons/views.svg");
            background-size: 1.6rem 1.2rem;
            width: 1.6rem;
            height: 1.2rem;

            @supports (mask-repeat: no-repeat) {
                background: var(--color-black);
                mask-image: url("../icons/views.svg");
                mask-size: 1.6rem 1.2rem;
                mask-repeat: no-repeat;
            }
        }
    }

    &.-edit {
        &::before {
            content: '';
            display: inline-block;
            background-image: url("../icons/edit.svg");
            background-size: 1.9rem 1.9rem;
            width: 1.9rem;
            height: 1.9rem;

            @supports (mask-repeat: no-repeat) {
                background: var(--color-black);
                mask-image: url("../icons/edit.svg");
                mask-size: 1.9rem 1.9rem;
                mask-repeat: no-repeat;
            }
        }
    }

    &.-bin {
        transition: background-color .3s ease;

        &::before {
            content: '';
            display: inline-block;
            background-image: url("../icons/bin.svg");
            background-size: 1.7rem 1.7rem;
            width: 1.7rem;
            height: 1.7rem;

            @supports (mask-repeat: no-repeat) {
                background: var(--color-black);
                mask-image: url("../icons/bin.svg");
                mask-size: 1.7rem 1.7rem;
                mask-repeat: no-repeat;
            }
        }

        &:hover, &:focus {
            background-color: var(--color-red);

            @supports (mask-repeat: no-repeat) {
                &::before {
                    background: var(--color-white);
                }
            }
        }
    }

    .form__actions & {
        width: 100%;
        margin: .5rem 0;
    }
}

.buttons {
    .button {
        margin: .4rem 2.4rem .4rem 0;
    }
}

@media (--screen-medium-large) {
    .button {
        .form__actions & {
            max-width: 22.5rem;
            box-sizing: border-box;
        }
    }
}
