.block {
    margin: 0;
    padding: 0 2rem;

    &.-size-wide {
        padding: 0;
    }
}

.block__inner {
    width: 72rem;
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;

    .block.-size-wide & {
        max-width: 103.9rem;
        width: auto;
    }
}
