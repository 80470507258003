.vacancy-aside {
    width: 100%;
    margin-bottom: 4rem;

    .button.-full-width {
        svg {
            margin: 0 0 -.2rem .8rem;
        }
    }

    &.-mobile {
        .button.-full-width {
            display: none;
        }
    }
}

.vacancy-aside__inner {
    .vacancy-aside.-desktop & {
        display: none;
    }
}

.vacancy-aside__summary {
    background-color: var(--color-off-white);
    border-radius: .4rem;

    .button + & {
        margin-top: 4rem;
    }
}

.vacancy-aside__summary__title {
    background-color: #F0F0F0;
    border-radius: .4rem .4rem 0 0;
    padding: 1.5rem 2.5rem;
}

.vacancy-aside__summary__content {
    @include no-list;
    padding: 1.5rem 2.5rem;
}

li.vacancy-aside__summary__content__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.6rem;
    color: var(--color-gray);
    line-height: 1.3;

    svg {
        fill: var(--color-primary);
        margin: .3rem 1.5rem 0 0;
    }

    p {
        margin: 0;

        & span {
            display: block;

            &:first-of-type {
                font-weight: var(--font-weight-bold);
            }
        }
    }
}

.vacancy-aside__share {
    margin: 5rem 0;

    h4 + p {
        margin: .8rem 0;
    }

    .button {
        margin-bottom: 4rem;
    }
}

.vacancy-aside__share__socials {
    margin: 0 0 1rem;
}

@media (--screen-medium-large) {
    .vacancy-aside {
        width: 25rem;
        min-width: 25rem;
        margin: 0 0 3rem 3rem;

        &.-mobile {
            display: none;
        }
    }

    .vacancy-aside__inner {
        .vacancy-aside.-desktop & {
            display: block;
        }
    }

    .vacancy-aside__summary__title {
        padding: 2.2rem 3.5rem;
    }


    .vacancy-aside__summary__content {
        padding: 3rem 3.5rem;
    }

    li.vacancy-aside__summary__content__item {
        margin-bottom: 3rem;
    }
}

@media (--screen-large) {
    .vacancy-aside {
        width: 34rem;
        min-width: 34rem;
        margin: 0 0 5rem 5rem;
    }
}
