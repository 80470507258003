.tag {
    cursor: default;
    display: inline-flex;
    align-items: center;
    background-color: var(--color-primary-lighest);
    padding: .8rem;
    border-radius: .4rem;
    margin: 0 .6rem .6rem 0;

    font-size: 1.4rem;
    font-weight: var(--font-weight-semiBold);
    color: var(--color-primary);
    text-decoration: none;
    font-style: normal;
    text-transform: capitalize;

    svg {
        display: inline-block;
        margin-right: .8rem;
        fill: var(--color-primary);
    }

    input {
        position: absolute;
        left: 0.4rem;
        opacity: 0;
    }

    .filter__tags & {
        display: none;

        &.-checked {
            display: inline-block;
        }
    }

    &[href] {
        cursor: pointer;
    }
}
