@mixin no-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0;

        &::before {
            display: none;
        }
    }
}

@mixin image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@mixin box {
    box-shadow: 0 .2rem 1rem 0 rgba(248, 249, 250, .2), 0 .2rem 1rem 0 rgba(0, 0, 0, .2);
    border-radius: .5rem;
}

@mixin svg {
    display: inline-block;
    margin: 0 .8rem -.2rem 0;
}
