.form__radio-box__wrapper {
    margin: 3rem 0 6rem;
}

.form__radio-box {
    text-align: left;
    background: var(--color-blue-background);
    border: .2rem solid var(--color-primary);
    border-radius: 1rem;
    padding: 2rem 2.5rem;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    &.-secondary {
        background-color: var(--color-secondary-lighest);
        border: .2rem solid var(--color-secondary);
    }

    & + & {
        margin-top: 2rem;
    }

    & + button[type="submit"] {
        margin-top: 6rem;
    }

    & small {
        margin: 0 !important;
    }
}

.form__radio-box__left {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .form__radio {
        width: 3.4rem;
        height: 3.4rem;
        margin-right: 2.4rem;


        + .form__label {
            font-size: 2rem;
            color: var(--color-gray-medium);
            padding: 5rem 0 0;

            small {
                display: block;
                font-size: 1.6rem;
                color: var(--color-gray);
            }

            &::before,
            &::after {
                width: 3.4rem;
                height: 3.4rem;
            }

            &::before {
                background-color: var(--color-off-white);
                border: .2rem solid var(--color-text-light);
                box-sizing: border-box;
            }

            &::after {
                top: 0;
                left: 0;
                background-image: url("../icons/checkmark.svg");
                background-size: 1.8rem 1.5rem;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
}

.form__radio-box__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 4.8rem;
    color: var(--color-gray-medium);
    margin: 0 0 0 auto;

    & > em {
        font-size: 1.3rem;
        color: var(--color-text-light);
        font-style: normal;
        line-height: 1.4;
        margin-top: -1rem;
    }
}

@media (--screen-medium-large) {
    .form__radio-box__left {
        .form__radio {
            & +.form__label {
                padding: 0 1rem 0 6rem;
            }
        }
    }
}
