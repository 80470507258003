.testimonials {
    padding: 4rem 0;
    background-color: var(--color-off-white);
    text-align: center;

    .container {
        max-width: 121rem;
    }
}

.testimonials__items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
}

.testimonials__item {
    display: inline-block;
    max-width: 35rem;
    margin: 0 1.5rem 2rem;
    box-sizing: border-box;

    @include box;
    background-color: var(--color-white);
    padding: 2rem 3rem;

    text-align: left;

    p {
        color: var(--color-gray);
    }
}

@media (--screen-medium-large) {
    .testimonials {
        padding: 5.7rem 0 12rem;

        h2 {
            margin-bottom: 4.2rem;
        }
    }


    .testimonials__item {
        max-width: calc(50% - 3rem);
        width: 35rem;
    }
}

@media (min-width: 800px) {
    .testimonials__item {
        max-width: calc(33.33% - 3rem);
    }
}
