html {
    font-size: 62.5%;
    min-width: 320px;

    touch-action: manipulation;
}

/* stylelint-disable plugin/selector-tag-no-without-class */
div, section, article {
    margin: 0;
    padding: 0;
}
/* stylelint-enable plugin/selector-tag-no-without-class */
