.button-row {
    margin: 1rem 0 2.2rem;

    [data-align="buttons-centered"] & {
        text-align: center;
    }

    > * {
        margin: 0 2rem 2rem 0;

        &:last-child {
            margin-right: 0;
        }
    }
}
