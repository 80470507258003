.vacancy-card {
    position: relative;
    display: block;
    margin-bottom: 3.5rem;
    @include box;
    color: var(--color-text);
    text-decoration: none;
    overflow: hidden;

    p {
        margin: 0;
    }

    &.-premium {
        background-color: var(--color-secondary-lighest);
        border: .2rem solid var(--color-orange);
    }

    &:hover, &:focus {
        color: var(--color-text);
    }
}

.vacancy-card__company {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 3.5rem 2rem;

    color: var(--color-gray);
    font-size: 1.6rem;

    & .title-5 {
        display: block;
        margin: .6rem 0 1.6rem;
    }
}

.vacancy-card__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2.2rem 1rem 0;
    width: 5rem;
    height: 5rem;
    box-sizing: border-box;

    img {
        display: block;
        width: 100%;
    }
}

.vacancy-card__content__company {
    em {
        font-style: normal;
        margin-right: 1.7rem;
    }

    & .badge {
        vertical-align: text-top;
        padding: 0.15rem 0.8rem;
    }
}

.vacancy-card__content__text {
    display: block;
    color: var(--color-text);
    font-weight: var(--font-weight-semiBold);
    line-height: 1.7;
    margin: 0;
}

.vacancy-card__saved {
    display: block;
    margin-top: 1.6rem;
}

.vacancy-card__info {
    background-color: var(--color-off-white);
    padding: 2rem 2rem 1.5rem;
    color: var(--color-gray);

    span {
        display: inline-block;
        margin: 0 2rem 1rem 0;
    }

    svg {
        fill: var(--color-gray);
        width: 1.6rem;
        height: 1.6rem;
        @include svg;
    }

    .vacancy-card.-premium & {
        background-color: var(--color-orange-light);
    }
}

@media (--screen-large) {
    .vacancy-card__content {
        padding-right: 6rem;
    }

    .vacancy-card__company,
    .vacancy-card__info {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }

    .vacancy-card__company {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .vacancy-card__saved {
        margin-top: 0;
        position: absolute;
        top: 3.6rem;
        right: 3.6rem;
    }
}
