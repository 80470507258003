.form__fieldset {
    border: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .form__entry {
        width: 100%;
    }
}

@media (--screen-medium-large) {
    .form__fieldset {
        .form__entry {
            margin-right: 4rem;
            max-width: calc(50% - 2rem);

            &:last-of-type {
                margin-right: 0;
            }
        }

        &.-three-column {
            .form__entry {
                margin-right: 4rem;
                max-width: calc(100% / 3 - 8rem / 3);

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (--screen-large) {
    .form__fieldset {
        .form__entry {
            width: 47.5rem;
        }
    }
}
