.jumbotron {
    background-color: #FAFAFA;
    padding: 4rem 0;
}

.jumbotron__image {
    img {
        @include image;
        max-width: 53rem;
        margin: 0 auto 2rem;
    }
}

.jumbotron__content {
    max-width: 57rem;
    margin: 0 auto 0 0;

    h1 {
        font-weight: var(--font-weight-semiBold);
        color: var(--color-primary);
        margin-bottom: .8rem;
    }

    p {
        font-size: 2rem;
        color: var(--color-gray-medium);
        line-height: 1.5;
    }
}

@media (--screen-large) {
    .jumbotron {
        padding: 7.4rem 0;

        .container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    .jumbotron__image {
        img {
            @include image;
            max-width: 53rem;
            margin: 0 auto;
        }
    }

    .jumbotron__content {
        p {
            font-size: 2.4rem;
        }
    }
}

