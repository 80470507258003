.row.downloads {
    margin: 3rem 0 5rem;
}

.download {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2rem 2rem 1rem;
    margin: 0;

    & + & {
        border-top: .1rem solid var(--color-borders);
    }
}

.download__info {
    display: flex;
    margin: 0 2rem 1rem 0;

    &::before {
        content: '';
        display: inline-block;
        margin-right: 3rem;
        background-image: url("../icons/default-file.svg");
        background-size: 5rem 5rem;
        width: 5rem;
        height: 5rem;
    }
}

.download__title {
    margin: 0 0 .6rem;
}

.download__meta {
    display: flex;
    align-items: center;

    span {
        font-size: 1.6rem;
        color: var(--color-gray);
        white-space: nowrap;

        &:not(:last-of-type) {
            margin-right: 3rem;
        }
    }
}

@media (--screen-medium-large) {
    .row.downloads {
        margin: 5.6rem 0 10rem;
    }

    .download {
        padding: 3.5rem 3.5rem 2.5rem;
    }
}
