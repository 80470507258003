.dropdown {
    @include no-list;
    z-index: 3;
}

.dropdown__trigger {
    span {
        display: none;
    }
}

.dropdown__panel {
    @include no-list;
    display: flex;
    align-items: center;
}

.dropdown__link {
    display: block;
    padding: 0 .8rem;
    color: var(--color-white);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: all .2s ease;

    li:first-of-type & {
        padding-left: 0;
        border-right: .2rem solid var(--color-white);
    }

    &:hover, &:focus, &.-active {
        color: var(--color-white);
        text-decoration: underline;
    }

    .dropdown.-user & {
        display: inline-block;
    }
}


@media (--screen-medium-large) {
    .page-header:not(.-open) {
        .dropdown {
            display: block;
            position: relative;

            &.-user {
                .dropdown__panel {
                    min-width: 31rem;
                }

                .dropdown__link {
                    padding: 0 0 1.1rem;
                }

                .page-header__user__login .dropdown__link {
                    padding: 0;
                }
            }
        }

        .dropdown__trigger {
            padding: 1rem;
            cursor: pointer;

            span {
                display: block;
                position: relative;
                white-space: nowrap;
                transition: all .3s ease;

                &::after {
                    content: '';
                    display: inline-block;
                    vertical-align: top;
                    margin-left: .8rem;
                    background-image: url("../icons/dropdown.svg");
                    background-repeat: no-repeat;
                    background-size: 1.7rem 1.7rem;
                    width: 1.7rem;
                    height: 1.7rem;
                    transition: all .3s ease;
                }

                .dropdown.-open & {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .dropdown__panel {
            @include box;
            font-weight: var(--font-weight-bold);
            margin-top: .8rem;
            z-index: 1;

            display: none;
            width: 6.6rem;
            position: absolute;
            top: 7.2rem;
            left: 50%;
            transform: translateX(-53%);
            box-sizing: border-box;
            background-color: var(--color-white);
            opacity: 0;
            max-height: 0;
            transition: opacity .3s ease, max-height .3s ease;

            &::before {
                content: '';
                position: absolute;
                top: -1rem;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 1.05rem 1rem 1.05rem;
                border-color: transparent transparent #9e9e9e transparent;
            }

            &.-open {
                display: block;
                opacity: 1;
                max-height: none;
            }
        }

        .dropdown__link {
            line-height: 1.6;
            margin: 0;
            padding: .8rem 1rem;
            opacity: 1;

            &.-active {
                display: none;
            }
        }
    }
}
