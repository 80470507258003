.company-aside {
    display: block;
    width: 100%;
    margin-bottom: 4rem;
}

.company-aside__socials {
    margin-bottom: 4rem;
}

.company-aside__jobalert__subtitle {
    margin-bottom: 1.2rem;
}

@media (--screen-medium-large) {
    .company-aside {
        width: 20rem;
        min-width: 20rem;
        margin: 0 0 3rem 3rem;    }
}


@media (--screen-large) {
    .company-aside {
        width: 27rem;
        min-width: 27rem;
        margin: 0 0 5rem 5rem;
    }
}
