.form__label {
    color: var(--color-label);
    margin-bottom: .4rem;

    .form__checkbox + & {
        width: 100%;
        color: var(--color-text);
    }

    &.-hidden {
        display: none;
    }
}

[readonly] + label {
    cursor: default;
}
