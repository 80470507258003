/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-white:                  #FFFFFF;
    --color-off-white:              #F9F9F9;
    --color-black:                  #000000;
    --color-primary: 			    #17355D;
    --color-primary-80:             rgba(23, 53, 93, .8);
    --color-primary-hover: 		    #214D87;
    --color-primary-light:          #2462B2;
    --color-primary-lighest: 	    #E2E8F7;
    --color-secondary: 			    #FCAB26;
    --color-secondary-hover: 	    #FDC973;
    --color-secondary-lighest: 	    #F9F1E1;
    --color-success: 			    #38B653;
    --color-success-30: 		    rgba(56,182,83,.3);
    --color-danger: 			    #DC3545;
    --color-danger-30: 			    rgba(220,53,69,.3);
    --color-danger-hover:           rgba(220, 53, 69, .9);
    --color-red:                    #EF1919;
    --color-warning: 			    #FFC107;
    --color-yellow:                 #FEB342;
    --color-info: 				    #0DCAF0;
    --color-gray: 				    #6C757D;
    --color-gray-medium:            #333333;
    --color-gray-dark: 			    #343A40;
    --color-gray-dark-40:           rgba(52, 58, 64, .4);
    --color-gray-button:            #EEEEEE;
    --color-gray-button-dark:       #C0C0C0;
    --color-text: 				    #666666;
    --color-text-light: 		    #9E9E9E;
    --color-label:                  #394858;
    --color-menu: 				    #BDD1FF;
    --color-coal:                   #2A2A2A;
    --color-borders:                #E0E0E0;
    --color-orange:                 #EC9200;
    --color-orange-light:           #F2E0BB;
    --color-blue:                   #2462B2;
    --color-blue-light:             #E3EAFE;
    --color-blue-background:        #E6EDFF;
    --color-blue-background-2:      #CEDBFF;

    --font-weight-thin: 		100;
    --font-weight-extraLight: 	200;
    --font-weight-light: 		300;
    --font-weight-regular: 		400;
    --font-weight-medium: 		500;
    --font-weight-semiBold: 	600;
    --font-weight-bold: 		700;
    --font-weight-extraBold: 	800;
    --font-weight-black: 		900;

    --font-family:              'Nunito', sans-serif;
    --default-text-color:       var(--color-text);
}

@custom-media --screen-large        (width >= 850px);

@custom-media --screen-medium-large (width >= 630px);
@custom-media --screen-medium       (width >= 630px) and (width < 850px);

@custom-media --screen-small-large  (width >= 420px);
@custom-media --screen-small-medium (width >= 420px) and (width < 850px);
@custom-media --screen-small        (width >= 420px) and (width < 630px);

@custom-media --screen-mini-medium  (width < 850px);
@custom-media --screen-mini-small   (width < 630px);
@custom-media --screen-mini         (width < 420px);
