/* JUMBOTRON */


.jumbotron {
	padding: 25px 0px 25px 0px;


	margin-bottom: -29px;
}

.jumbotron__image {
	/* background-color: #e2e8f7; */
	padding: 10px 20px 0px 20px;
	margin-top: 30px;


}

.jumbotron__content p {
	font-size: 18px;
	margin-top: 40px;
}


.jumbotron__image img {
	position: relative;
	bottom: -25px;
	height: 280px;
	width: 430px;
}




@media (max-width: 1032px) {
	.jumbotron__image img {
		height: 336px;
		width: 516px;
		bottom: -25px;


	}

}

@media (max-width: 1016px) {
	.jumbotron__image img {
		height: 336px;
		width: 516px;
		bottom: -30px;


	}

}

@media (max-width: 992px) {
	.jumbotron__image img {
		height: 300px;
		width: 470px;
		position: relative;
		right: 20px;
		bottom: -34px;
	}

}


@media (max-width: 970px) {
	.jumbotron__image img {
		height: 336px;
		width: 516px;
		position: relative;
		right: 10px;
		bottom: -30px;
	}

}


@media (max-width: 898px) {
	.jumbotron__image img {
		height: 340px;
		width: 470px;
		position: relative;
		right: 20px;
		bottom: -24px;
	}

}




@media (max-width: 849px) {
	.jumbotron__image img {
		height: 300px;
		width: 470px;
		position: relative;

		bottom: -10px;
	}

}


@media (max-width: 473px) {
	.jumbotron__image img {
		height: 250px;
		width: 350px;
		position: relative;

		bottom: -10px;
	}

}


@media (max-width: 414px) {
	.jumbotron__image img {
		height: 210px;
		width: 290px;
		position: relative;
		bottom: -10px;
		left: 2.5%;
	}

	.jumbotron {
		padding: 0px;

	}

	.jumbotron__content p {
		font-size: 18px;
		margin-top: 10px;
	}

	a.button.-search {
		margin-top: -30px;
	}

}




@media (max-width:875px) {
	a.button.-outline {

		margin-bottom: 25px;

	}


}



@media (max-width:578px) {
	a.button.-search {
		width: 100%;
	}

	a.button.-outline {
		width: 100%;
		margin-bottom: 25px;

	}


}






/* PREMIUM */




section#premiumjobs {
	background-color: #17355d;
	padding: 20px 0px;
	margin: 25px 0px;
}



.wrapper {
	max-width: 150rem;
	margin: 0 auto;
}


.premium__top {
	margin-left: 7%;
}

.premium__top h2 {
	margin: 0 2rem 0 0;
	color: #fff;
	font-weight: bolder;
	text-align: left;
	font-size: 2.5rem;
}

.premiumprofiles {
	width: 90%;
	margin: auto;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	padding: 40px 20px;
}

.premiumcard {

	flex-direction: column;
	flex-basis: calc(20% - 10px);
	height: 165px;
	margin-bottom: 60px;
	display: block;
	background-color: #e2e8f7;
	border-radius: 0.5rem;
	margin-bottom: 60px;
}

.premiumcard>div {}

.card-image {
	max-width: 250px;
	height: 120px;
}

.card-image>img {
	width: 100px;
	height: 100px;
	object-fit: cover;
	object-position: bottom;
	border-radius: 0.5rem;
	margin: 10px;

}

.card-image img {
	border-radius: 0.5rem;
}

.card-text {
	position: relative;
	font-weight: 600;
	height: 75px;
	height: 80px;
	background-color: #e2e8f7;
	color: #17355d;
	padding: 0px 10px;
	margin-bottom: 20px;


	border-radius: 0.5rem;
}

.locatieicon {

	background-image: url(../components/icons/location.svg);
	background-repeat: no-repeat;
	position: absolute;
	height: 15px;
	width: 15px;
	left: 10px;
	top: 45px;
	font-size: 12px;
}


.locatie {

	position: absolute;
	top: 35px;
	left: 30px;
	font-size: 12px;
	max-width: 80%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}



.vacancy__tag__item {
	background-color: #ffc23e;

}


.card-titel {

	line-height: 1.2em;
	max-height: 2.4em;
	overflow: hidden;
	text-overflow: ellipsis;

	font-weight: 600;
	font-size: 15px;
}

.premiumcard a {
	text-decoration: none;
}


@media (max-width:1199px) {
	.premiumcard {

		flex-direction: column;
		flex-basis: calc(25% - 20px);
		height: 200px;

		margin-bottom: 60px;

		display: block;
		background-color: #e2e8f7;
		border-radius: 0.5rem;
		margin-bottom: 60px;
	}

}








@media (max-width:822px) {
	.premiumcard {

		flex-direction: column;
		flex-basis: calc(50% - 20px);
		height: 200px;

		margin-bottom: 60px;

		display: block;
		background-color: #e2e8f7;
		border-radius: 0.5rem;
		margin-bottom: 60px;
	}

	.locatie {
		/* bottom: ; */
	}

}


@media (max-width:575px) {
	.premiumprofiles .premiumcard:last-child {
		display: none;
	}
}




/* Small screens */
@media only screen and (max-width: 575px) {
	.premiumprofiles .premiumcard:last-child {
		display: none;
	}
}

/* Medium screens */
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.premiumprofiles .premiumcard:last-child {
		display: none;
	}
}

/* Large screens */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.premiumprofiles .premiumcard:last-child {
		display: none;
	}
}

/* Extra-large screens */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.premiumprofiles .premiumcard:last-child {
		display: none;
	}
}

/* XXL screens */
@media only screen and (min-width: 1200px) {
	/* CSS styles for XXL screens */
}




/* Vacatures */

.vacancies h2 {
	font-weight: bolder;
	text-align: center;
	font-size: 2.5rem;
}


.vacancies__list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 50px;
	padding: 10px;
	box-shadow: none;

}

.vacancy__list__item {
	flex-basis: calc(33% - 20px);
	height: 300px;
	margin: 0 1.5rem 2rem;
	box-sizing: border-box;
	box-shadow: 0 0.2rem 1rem 0 rgba(248, 249, 250, .2), 0 0.2rem 1rem 0 rgba(0, 0, 0, .2);
	border-radius: 0.5rem;
	background-color: #fafafa;
	border-top: 4px solid #17355d;
	margin: 5px auto;

}


li.vacancy__list__item {
	margin-bottom: 30px;
	margin-left: 10px;
	margin-right: 10px;
}

.vacancy {
	position: relative;
}


/* Media queries for responsive design */
@media (max-width: 980px) {
	.vacancy__list__item {
		flex-basis: calc(50% - 20px);
		/* 2 columns on tablet */

	}
}

@media (max-width: 576px) {
	.vacancy__list__item {
		flex-basis: 100%;
		/* 1 column on mobile */
		width: 100%;
	}

	.vacancy__content__title {}
}

.vacancy {
	height: 100%;


}

.vacancy-button {
	display: none !important;
}


.vacancy__logo {
	position: absolute;
	text-align: center;
	left: 32.5%;

	top: 50px;
	width: 35%;
}

.vacancy__logo img {
	width: 115px;
	border-radius: 0.5rem;
}



.vacancy__content__title {
	max-width: 90%;
	position: absolute;
	top: 190px;
	left: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: bolder;
	font-size: 16px;
}


li.-premium .vacancy__content__title {
	max-width: 90%;
	position: absolute;
	top: 190px;
	left: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 800;
	font-size: 14.5px;
}

.-company {
	position: absolute;
	top: 160px;
	left: 20px;
	font-size: 15px;
	width: 95%;
	-webkit-line-clamp: 1;
	text-overflow: ellipsis;
}




.-employment {
	position: absolute;
	bottom: 20px;
	left: 20px;
	font-size: 12px;
}

.-location {
	position: absolute;
	bottom: 20px;
	right: 10px;
	font-size: 12px;
}



.vacancy__list__item .-premium {
	flex-basis: calc(33% - 20px);
	/* 3 columns on desktop */
	height: 100%;
	box-sizing: border-box;
	box-shadow: 0 0.2rem 1rem 0 rgba(248, 249, 250, .2), 0 0.2rem 1rem 0 rgba(0, 0, 0, .2);
	border-radius: 0.5rem;
	color: #000;
	background-color: #FFF9E6;
	border: none;
}



li.vacancy__list__item.-premium .vacancy__content {
	color: #6c757d;
}


li.vacancy__list__item.-premium .vacancy__content__title {
	color: #000;

}



.vacancy__tag__item {
	position: relative;
	left: 5px;
	bottom: 10px;
	font-weight: 500;
}



@media (max-width: 980px) {}








/*
TESTIMONIALS */


.testimonials h2 {
	font-weight: bolder;
	font-size: 2.5rem;
}



.testimonials__items {
	text-align: center;
}



.testimonials__item {
	text-align: center;
}




.testimonials__item img {
	display: none;
}



.testimonials__item .title-5 {
	font-weight: bolder;
}






/* BANNERS */


.banner-company {
	position: relative;
	max-height: 350px;
}



.banner-company__logo {
	position: absolute;
	right: 35px;
	bottom: 0px;
	transform: scale(1.5, 1.5);
	max-width: 40%;

}

.banner-company__content {

	width: 60%;
	font-weight: 900px;
	line-height: 30px;

	color: #17355d;
	padding-left: 15px;
	text-align: center;
}



@media (max-width: 850px) {
	.banner-company__logo {
		display: none;
	}

	.banner-company__content {
		margin-left: 20%;
		width: 60%;
		font-weight: 900px;
		line-height: 30px;
		color: #17355d;
		padding-left: 15px;
		text-align: center;
	}
}


@media (max-width: 507px) {
	section.banner-company {
		height: auto;
		padding-bottom: 230px;
	}

}


.partners__list {
	width: 90%;
	margin: auto;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	padding: 40px 20px;

}


.partners__list__item {
	-webkit-box-shadow: 0px 0px 10px 0px #dddddd;
	-moz-box-shadow: 0px 0px 10px 0px #dddddd;
	box-shadow: 0px 0px 10px 0px #dddddd;
	margin: 10px;
	border-radius: 3px;
	z-index: 1;
	overflow: hidden;
	border: 1px solid #eaeaea;
	background: transparent;
	display: flex;
	flex-direction: column;
	flex-basis: calc(15% - 10px);
	box-sizing: border-box;
	position: relative;
}


.partner {
	height: auto;

}


.partners__list__item a {
	padding: 5%;
	width: 90%;
	height: 90%;

}







@media (max-width:1199px) {
	.partners__list__item {

		flex-direction: column;
		flex-basis: calc(20% - 10px);

		display: block;

	}

	.partners__list .partners__list__item:last-child {
		margin-top: -20px;
	}


	li.partners__list__item {
		margin-bottom: 20px;
	}

	.partners__list .partners__list__item:last-child {
		display: none;
	}

}




@media (max-width:822px) {
	.partners__list__item {

		flex-direction: column;
		flex-basis: calc(25% - 10px);


	}

	li.partners__list__item {
		margin-bottom: 20px;
	}

	.partners__list .partners__list__item:nth-child(5) {
		display: none;
	}

	.partners__list .partners__list__item:last-child {
		display: show;
	}

}



@media (max-width:576px) {
	.partners__list__item {

		flex-direction: column;
		flex-basis: calc(50% - 10px);


	}

	li.partners__list__item {
		margin-bottom: 20px;
	}


	.partners__list .partners__list__item:last-child {
		display: none;
	}

}






























































/* VACATURES LISTING */

.vacancy-card__company {
	display: flex;
	/* align-items: center; */
}

.vacancy-card__image-new {
	width: 125px;
	height: 125px;
	margin-right: 16px;
}

.vacancy-card__image-new img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 0.5rem;
}

.vacancy-card__content {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.title-5 {
	font-weight: bolder;
}





.vacancy-card__content__text {
	width: 95%;

	max-height: 60px;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;

}

.vacancy-card__info span {
	color: #000;
}

.-premiumnew {
	min-height: 300px;
}


.vacancy-card.-premiumnew {
	background-color: #FFF9E6;
	border: 0.3rem solid #ffc23e;
}


.vacancy-card.-premiumnew .vacancy-card__info {
	background-color: #f2e0bb;
	background-color: var(--color-orange-light);
}



.vacancy__tag__item-new {
	background-color: #ffc23e;
	color: #fff;
	padding: 0.5rem 1.8rem;
	border-radius: 0.4rem;

}

.title-5 {
	font-size: 2.2rem;
}


.vacancy-card.-premiumnew .vacancy-card__info {
	position: absolute;
	bottom: 0px;
	width: 100%;
}


.vacancy-card__saved {
	margin-top: 0;
	position: absolute;
	top: 5rem;
	right: 10px;
}




@media (max-width:852px) {
	.-premiumnew {
		min-height: 450px;
	}

	.vacancy-card__content__text {
		display: none;
	}

}









/* Vacatures-jurist */


ul.vacancies__list-old {
	margin: 1rem 0 3.2rem;
	padding: 0 0 0 1.7rem;
	list-style: none;
	line-height: 1.67;
}


.vacancies__list-old {
	list-style: none;
	padding: 0px;
	box-shadow: none;
	border-radius: none;
	margin: 3rem 0;
}




.vacancy__tag__item-old {
	background-color: #ffc23e;
	padding: .2rem .8rem;
	border-radius: .4rem;
	font-size: 1.3rem;
	color: #fff;
	color: var(--color-white);
	line-height: 1.54;

}

.vacancy__tag-old {
	font-weight: 800;
}

.vacancy__tag-old {
	position: absolute;
	top: 1.1rem;
	right: .9rem;
}





.vacancy__content-old {
	margin-top: 30px;
}


.vacancy,
.vacancy.-premiumold {
	padding: 2rem 2rem;
}





li.vacancy__list__item-old::before {
	content: none;
}


.vacancy__list__item-old {
	margin: 0 1.5rem 2rem;
	box-sizing: border-box;
	box-shadow: 0 0.2rem 1rem 0 rgba(248, 249, 250, .2), 0 0.2rem 1rem 0 rgba(0, 0, 0, .2);
	border-radius: 0.5rem;
	background-color: #fafafa;
	padding: 10px;
}

.vacancy__list__item-old ul li::before {
	width: 0px;
	height: 0px;
}



li.vacancy__list__item-old {
	list-style-type: none;
}


li.vacancy__list__item-old {
	margin-bottom: 30px;
	margin-left: 10px;
	margin-right: 10px;

}

.vacancy__list__item-old.-premiumold {
	margin: 0 1.5rem 2rem;
	box-sizing: border-box;
	box-shadow: 0 0.2rem 1rem 0 rgba(248, 249, 250, .2), 0 0.2rem 1rem 0 rgba(0, 0, 0, .2);
	border-radius: 0.5rem;

	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);

	border: none;
}


li.vacancy__list__item-old.-premiumold {

	margin-left: 10px;
	margin-right: 10px;

	background-color: #FFF9E6;
	border: 0.3rem solid #ffc23e;

}

a.vacancy.-premiumold {
	border: none;
	background-color: #FFF9E6;

}


.vacancy__logo-old {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 3rem 1rem 0;
	width: 5rem;
	height: 5rem;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: .2rem;
	box-sizing: border-box;
}



.vacancy__content-old {
	color: #6c757d;
	color: var(--color-gray);
}



.vacancy__logo-old {


	background-color: #fff;
	top: 50px;
	width: 115px;
	height: 115px;

	border-radius: 0.5rem;
}

.vacancy__logo-old img {
	width: 115px;
	border-radius: 0.5rem;
}



.vacancy__content__title-old {
	display: block;
	font-size: 1.8rem;
	color: #333;
	color: var(--color-gray-medium);
}


@media (min-width: 630px) {
	.vacancy__content__title-old {
		font-size: 2rem;
	}

	.vacancies__list-old {
		margin: 3rem 0 5.5rem;
	}

}


.vacancy__content__info.-location-old::before {
	-webkit-mask-image: url(../components/icons/location.svg);
	mask-image: url(../components/icons/location.svg);
}



.vacancy__content__info.-company-old::before {
	-webkit-mask-image: url(../components/icons/company.svg);
	mask-image: url(../components/icons/company.svg);
}

.vacancy__content__info.-employment-old::before {
	-webkit-mask-image: url(../components/icons/job.svg);
	mask-image: url(../components/icons/job.svg);
}


.vacancy-button-old {
	position: relative;
	padding: 0;
	cursor: pointer;
	width: 3.8rem;
	height: 3.8rem;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.vacancy-button-old svg {
	transition: fill .2s ease;
}


.vacancy__right-old {
	display: flex;
	align-items: center;
	min-width: 3.8rem;
	margin: 1rem 0 0 auto;
}


@media (min-width: 630px) {
	.vacancy__right-old {
		margin: 0 0 0 auto;
	}
}




























.vacancy__list__item .vacancy__content__info.-location {
	text-overflow: ellipsis;
	max-width: 190px;
	overflow: hidden;
	white-space: nowrap;
}








/* DETAIL SINGLE PAGINA */



.page-head__content__info-detail:before {
	content: "";
	display: inline-block;
	margin: 0 0.8rem -0.2rem 0;
	background-size: 1.6rem 1.6rem;
	width: 1.6rem;
	height: 1.6rem;
}



.page-head__content__info-detail {
	margin-right: 2.2rem;
	white-space: nowrap;
	color: #6c757d;
	color: var(--color-gray);
	text-decoration: none;
}



.page-head__content__info-detail {
	color: #fff;
}



.page-head__content__info-detail::before {
	color: #fff;
}


a.page-head__content__info-detail.page-head.-company {
	color: #fff;
}




.page-head__content {
	max-width: 80%;
	color: #fff;
}

.page-head__content p {
	max-width: 80%;
	color: #fff;
}

.page-head__logo {
	padding: 0px;
	border-radius: 0, 5rem;
}


.page-head__logo img {
	border-radius: 0.5rem;
}




.page-head h1 {
	padding-top: 30px;
	font-size: 36px;
	font-weight: bolder;
	margin: 0;
	color: #fff;
	min-width: 320px;

}

.page-head.-company-detail::after,
.page-head.-profile::after,
.page-head.-vacancy::after {
	background: rgb(23, 53, 93);
	background: linear-gradient(90deg, rgba(23, 53, 93, 1) 0%, rgba(83, 141, 211, 1) 21%);

}


.-flex-reverse {
	display: flex;

	position: relative;
}

.content {
	flex-wrap: wrap;
	width: 55%;

}





.page-head__content__info-detail.-location-detail::before, .page-head__content__info.-location-detail::before {
	background-image: url(../components/icons/location.white.svg);
	width: 1.6rem;
	height: 1.6rem;
	display: inline-block;
}



.page-head__content__info-detail.-company-detail::before,.page-head__content__info.-company-detail::before {
	background-image: url(../components/icons/building.white.svg);
	width: 1.6rem;
	height: 1.6rem;
	display: inline-block;
}


.vacancy-aside {
	flex-wrap: wrap;
	width: 40%;

	top: 50px;
}


/* .wrapper {
display: flex;
justify-content: space-between;
} */


.button-yellow.-full-width,
.form__radio__button+.-full-width.form__label {
	width: 100%;
}

.vacancy-aside .button-yellow svg {
	display: none;
}

.button-yellow,
.form__radio__button+.form__label {
	display: inline-block;
	margin: 0;
	padding: 1.4rem 2rem;
	/* background-color: #17355d; */
	background-color: var(--color-primary);
	border-radius: .4rem;
	color: #fff;
	color: var(--color-white);
	font-family: Nunito, sans-serif;
	font-family: var(--font-family);
	font-size: 1.6rem;
	text-decoration: none;
	text-align: center;
	line-height: 1.38;
	letter-spacing: .05rem;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	transition: background-color .3s ease;
}








.button-yel,
.form__radio__button+.form__label {
	display: inline-block;
	margin: 0;
	padding: 1.4rem 2rem;
	/* background-color: #17355d; */
	background-color: var(--color-primary);
	border-radius: 0.4rem;
	color: #fff;
	color: var(--color-white);
	font-family: Nunito, sans-serif;
	font-family: var(--font-family);
	font-size: 1.6rem;
	text-decoration: none;
	text-align: center;
	line-height: 1.38;
	letter-spacing: .05rem;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	transition: background-color .3s ease;
}


.button-yel.-medium,
.form__radio__button+.-medium.form__label {
	padding: .8rem 2rem;
}

.button-yel {
	background-color: #ffc23e;
	font-weight: 800;
	margin: 0 0 1rem;
}


.button-yel:hover {
	background-color: #ffce64;

}







.button-yellow {
	background-color: #ffc23e;
	font-weight: 800;
}


.button-yellow:hover {
	background-color: #ffce64;

}

.vacancy-aside__summary__title {
	display: none;
}

.vacancy-aside__summary__content {
	margin-top: 25px;
}


.vacancy-aside__summary {
	box-shadow: 0 0.2rem 1rem 0 rgba(248, 249, 250, .2), 0 0.2rem 1rem 0 rgba(0, 0, 0, .2);
	color: #17355d;
	margin-bottom: 25px;
}


li.vacancy-aside__summary__content__item {
	color: #17355d;
}




.large-photo {
	height: 0;
	padding-top: 61.9%;
	background-color: #333;
	margin-bottom: 1em;
}

@media (min-width:43.75em) {

	/* responsive layout */
	/* .wrapper {
  margin: 0 auto;
  position: relative;
} */
	.main {
		display: inline-block;
		width: 75%;
		vertical-align: top;
	}

	.sidebar {
		display: inline-block;
		width: 25%;
		vertical-align: top;
	}


	.fixedsticky {
		top: 0;
	}
}


.button.-heart {
	display:block;
	padding: 20px;
	bottom: 20px;
	border-radius: 0.5rem;
	background-color: #2462b2;
	margin-top: 50px;
	margin-bottom: -40px;
	width: 4rem;
	border-radius: .4rem;
}

@media (--screen-medium-large) {
	.button.-heart {
		padding: 20px;
		margin-left: 88%;
		position: absolute;
		right: 55px;
		bottom: 20px;
		border-radius: 0.5rem;
		margin-bottom: 10px;
		width: 4rem;
		border-radius: .4rem;
	}
}


.button.-heart::before,
.form__radio__button+.-heart.form__label::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background-color: transparent;


	background-size: 2.3rem 2.3rem;
	background-repeat: no-repeat;
	background-position: 50%;
	width: 4rem;
	height: 100%;
	border-radius: .4rem 0 0 .4rem;
}














.button.-heart:hover {
	background-color: #c8d7fa;
	/* change this to the desired hover color */
}






.button.-heart::before,
.form__radio__button+.-heart.form__label::before {

	border-radius: none;
}




.button.-heart::before,
.form__radio__button+.-heart.form__label::before {

	border-radius: .4rem;
}



/*

.card {
display: -ms-flexbox;
display: flex;
margin-bottom: 1.1428571429rem;
padding: 0;
background-color: #FFFFFF;
-webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
-ms-flex-direction: column;
flex-direction: column;
} */

h3.list__title {
	padding: 1rem 2rem 0.5rem 2rem;
	margin-bottom: 15px;
	/* padding-bottom: 1rem; */
}




.list--links li::before {
	display: none;
}


i.list__icon-arrow {
	background-image: url(../components/icons/arrow.geel.svg);
	background-repeat: no-repeat;
	width: 1.6rem;
	height: 1.6rem;
	display: inline-block;
	padding: 5px;
	margin: 20px;
}

ul {
	list-style: none;
}


.job-opening-related-jobs li {
	list-style: none;
}


li.job-opening-track a:hover {
	text-decoration: underline;
	text-decoration-color: #ffc23e;

}


.list--links li {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	border-top: 1px solid #E8E8E8;

}

.list--links li>a,
.list--links li>span {
	padding: 1rem 3rem 1rem 2rem;
}


.list--links a,
.list--links span {
	display: -ms-flexbox;
	display: flex;
	text-decoration: none;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}




a.tag {
	background-color: #ffc23e;
}

span.tag {
	background-color: #ffc23e;
}


a.page-head__content__info.-company:hover {
	color: #ffc23e;
	text-decoration: none;

}



@media (max-width: 839px) {
	.vacancy-aside {
		width: 100%;
	}

}


.job-card__info-klein {
	display: inline-block;
	margin: 0 1rem 0.5rem 0;
	font-size: 1.4rem;
	color: #666;
	color: var(--color-text);
	font-style: normal;
}

.job-card__info-klein.icon:before {
	margin-right: 0.4rem;
}



.icon.-employment-klein:before {
	background-image: url(../components/icons/job.svg);
}


.icon.-location-klein:before {
	background-image: url(../components/icons/location.svg);
}

.icon.-publicationDate-klein:before {
	background-image: url(../components/icons/time.svg);
}


ul li::before {
	position: absolute;
	left: -1.7rem;
	top: calc(.83em - .25rem);
	content: "";
	width: .5rem;
	height: .5rem;
	background-color: #17355d;
	background-color: var(--color-primary);
	border-radius: 50%;
}