html, body {
    height: 100%; // used to stick footer to bottom of page
    width: 100%;
    box-sizing: border-box;
    margin: 0;
}

body {
    font-family: var(--font-family);
    font-size: 1.6rem;
    line-height: 1.4;
    color: var(--default-text-color);

    &.-no-overflow {
        overflow-y: hidden;
    }

    &.-pop-up {
        position: relative;
        height: auto;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background-color: var(--color-gray-dark-40);
            width: 100%;
            height: 100%;
        }
    }

}
