.newsletter {
    padding: 2rem 0;

    .container {
        max-width: 120rem;
    }

    .form__entry.-checkbox {
        margin-top: .6rem;
    }

    .form__checkbox {
        margin-bottom: 0;
    }
}

.newsletter__image {
    width: 37rem;
    max-width: 50%;
    margin: 0 auto;

    img {
        display: block;
        width: 100%;
    }
}

.newsletter__content {
    h2 {
        margin: 0 0 1.4rem;
    }

    .title-5 {
        margin: 0 0 2.4rem;
    }

    .form__entry.-checkbox + .form__entry.-checkbox {
        margin-top: 0;
    }

    .form__label.-checkbox {
        max-width: none;
    }
}

.newsletter__fields {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .form__entry,
    .button[type="submit"] {
        width: 100%;
        margin: 0 0 1rem;
    }

    .form__label.-checkbox {
        max-width: none;
    }

    .button {
        white-space: nowrap;
    }
}

@media (--screen-medium-large) {
    .newsletter {
        padding: 13rem 0;
    }

    .newsletter__fields {
        .form__entry {
            width: 25rem;
            margin-right: 1rem;
        }

        .button[type="submit"] {
            width: auto;
        }
    }
}

@media (--screen-large) {
    .newsletter {
        .container {
            display: flex;
            align-items: center;
        }
    }
}
