.form__select {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 1.4rem;
        transform: translateY(-50%);
        background-image: url("../icons/select-arrows.svg");
        background-size: .8rem 1.7rem;
        background-repeat: no-repeat;
        width: .8rem;
        height: 1.7rem;
    }
}
