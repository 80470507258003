.partners {
    text-align: center;
    padding: 2rem 0 3.5rem;
}

.partners__list {
    @include no-list;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.partners__list__item {
    display: inline-block;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8rem;
        height: 8rem;
        padding: 0 2.5rem 2.5rem;
    }

    img {
        display: block;
        width: 100%;
    }
}

@media (--screen-medium-large) {
    .partners {
        h2 {
            margin-bottom: 5.4rem;
        }
    }

    .partners__list__item {
        a {
            width: 10.2rem;
            height: 10.2rem;
            padding: 0 3.5rem 2.5rem;
        }
    }
}
