.jobalert {
    display: block;
    width: 100%;
    padding: 1.8rem 1.5rem;
    box-sizing: border-box;

    &.-premium {
        background: var(--color-secondary-lighest);
        border: .2rem solid var(--color-orange);
    }

    .saved-items__list &:not(.-premium) {
        border-bottom: .1rem solid var(--color-borders);
    }
}

.jobalert__logo {
    display: block;
    margin: 0 3rem 1rem 0;
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: .2rem;
}

.jobalert__content {
    margin: 0 2rem 0 0;
    color: var(--color-gray);
    max-width: 83rem;

    a {
        text-decoration: none;
    }
}

.jobalert__content__title {
    display: block;
    font-size: 1.8rem;
    color: var(--color-gray-medium);
}

.jobalert__content__info {
    display: inline-block;
    margin-right: 2.2rem;

    &::before {
        content: '';
        display: inline-block;
        @include svg;
        background-size: 1.6rem 1.6rem;
        width: 1.6rem;
        height: 1.6rem;
    }

    &.-company {
        &::before {
            background-image: url("../icons/company.svg");
        }
    }

    &.-location {
        &::before {
            background-image: url("../icons/location.svg");
        }
    }

    &.-employment {
        &::before {
            background-image: url("../icons/job.svg");
        }
    }

    &.-category {
        &::before {
            background-image: url("../icons/category.svg");
        }
    }

    &.-frequency {
        &::before {
            background-image: url("../icons/time.svg");
        }
    }
}

.jobalert__right {
    display: flex;
    align-items: center;
    min-width: 3.8rem;
    margin: 1rem 0 0 auto;
}

.jobalert__action {
    display: inline-block;

    &::before {
        content: '';
        display: inline-block;
        @include svg;
        background-size: 1.8rem 2rem;
        width: 1.8rem;
        height: 2rem;
        pointer-events: none;
    }

    &.-edit {
        &::before {
            background-image: url("../icons/edit.svg");
        }
    }

    &.-delete {
        &::before {
            background-image: url("../icons/bin.svg");
        }
    }

    &:hover {
        cursor: pointer;
    }
}

@media (--screen-medium-large) {
    .jobalert {
        display: flex;
        padding: 3.8rem 3.5rem;
    }

    .jobalert__content__title {
        font-size: 2rem;
    }

    .jobalert__right {
        margin: 0 0 0 auto;
    }
}
