.page-footer {
    position: sticky; // used to stick footer to bottom of page
    top: 100vh; // used to stick footer to bottom of page
    background-color: var(--color-off-white);
    color: var(--color-gray-medium);

    a {
        display: inline-block;
        margin-bottom: 1.4rem;
        color: var(--color-gray-medium);
        text-decoration: none;

        &:hover, &:focus {
            color: var(--color-gray-medium);
            text-decoration: underline;
        }
    }
}

// page-footer__top
.page-footer__top {
    padding-top: 3rem;
}

.page-footer__top__column {
    margin-bottom: 1rem;
}

.page-footer__top__label {
    display: block;
    margin-bottom: 1.8rem;
    font-size: 2rem;
}

.page-footer__top__nav {
    @include no-list;

    .page-footer__top__column.-menu & {
        column-count: 2;
        white-space: nowrap;
    }
}

.page-footer__top__contact {
    margin-bottom: 2.3rem;

    a {
        display: block;

        img {
            margin-right: .8rem;
            vertical-align: middle;
        }
    }
}

// page-footer__bottom
.page-footer__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 3rem 0 1.4rem;
}

.page-footer__bottom__copy {
    margin: 0 4rem 1rem 0;
}

.page-footer__bottom__legal {
    a {
        white-space: nowrap;

        &:not(:last-of-type) {
            margin-right: 4rem;
        }
    }
}

@media (--screen-medium-large) {
    .page-footer__top {
        display: grid;
        grid-gap: 4rem;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
        "menu menu"
        "vacancies contact";
        padding: 10rem 0;
    }

    .page-footer__top__column {
        &.-menu {
            grid-area: menu;
        }

        &.-vacancies {
            grid-area: vacancies;
        }

        &.-contact {
            grid-area: contact;
        }
    }

    .page-footer__bottom {
        padding: 6rem 0 4.6rem;
    }
}

@media (--screen-large) {
    .page-footer__top {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: "menu menu vacancies contact";
    }
}
