.quote {
    margin: 1rem 0 3.2rem;
}

.quote__quote {
    margin: 0;
    color: var(--color-blue-1);
    font-size: 2.6rem;
    text-align: center;

    &::before {
        content: '\201C';
    }

    &::after {
        content: '\201D';
    }
}

.quote__author {
    margin: 1.6rem 0 0;
    color: var(--color-gray);
    font-size: 1.6rem;
    text-align: center;
}

@media (--screen-medium-large) {
    .quote__quote {
        font-size: 3.6rem;
    }

    .quote__author {
        font-size: 1.7rem;
    }
}
