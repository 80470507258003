.card {
    display: block;
    @include box;
    border-radius: .5rem;
    margin-bottom: 4rem;

    &.-hide, &.-hidden {
        display: none;
    }
}

.card__title {
    position: relative;
    border-bottom: .1rem solid var(--color-borders);
    padding: 2.5rem;
    margin: 0;
    font-weight: var(--font-weight-semiBold);
    font-size: 1.6rem;
    color: var(--color-gray-medium);

    svg {
        @include svg;
        fill: var(--color-primary);
    }

    &.-gray {
        background-color: var(--color-off-white);
    }

    .button.-bin {
        position: absolute;
        right: 2.5rem;
    }
}

.card__content {
    display: block;
    padding: 2.5rem;
    border-bottom: .1rem solid var(--color-borders);

    &:last-of-type {
        border-bottom: none;
    }

    & + .card__title {
        border-top: .1rem solid var(--color-borders);
    }

    &.-no-padding {
        padding: 0;
    }

    &.-vacancies {
        & > :nth-child(even):not(.-premium) {
            background-color: #FAFAFA;
        }
    }

    &.-flex {
        display: flex;
        flex-wrap: wrap;
    }
}

.card__content__list {
    @include no-list;

    & > :nth-child(n):not(:last-child):not(.-premium) {
        border-bottom: .1rem solid var(--color-borders);
    }
}

.card__content__top,
.card__content__bottom {
    display: block;

    p {
        margin-bottom: .6rem;
    }

    &.-hide {
        display: none;
    }
}

@media (--screen-medium-large) {
    .card__title {
        padding: 2.5rem 3.5rem;

        .button.-bin {
            position: absolute;
            right: 3.5rem;
        }
    }

    .card__content {
        padding: 3.5rem;
    }
}

@media (--screen-large) {
    .card__content {
        &.-flex {
            flex-wrap: nowrap;
        }
    }
}
