@import '~glightbox/dist/css/glightbox.min.css';

.gallery-wrapper {
    .title-4 {
        margin-bottom: 3.2rem;
    }
}

.gallery {
    @include no-list;

    display: block;
    column-count: 2;
    column-gap: 2rem;
}

li.gallery__item {
    display: block;
    margin-bottom: 2rem;

    img {
        display: block;
        width: 100%;
        object-fit: cover;
    }
}

.gallery__item__link {
    display: block;
    position: relative;

    text-decoration: none;

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: url("../icons/zoom.svg") no-repeat center;
        background-size: 3.9rem 3.8rem;
        width: 3.9rem;
        height: 3.8rem;

        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;

        background: var(--color-primary-80);
        width: 100%;
        height: 100%;

        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    &:hover,
    &:focus {
        &::before,
        &::after {
            opacity: 1;
        }
    }
}

@media (--screen-medium-large) {
    .gallery {
        column-count: 3;
        column-gap: 2.8rem;
    }

    li.gallery__item {
        margin-bottom: 2.8rem;
    }
}
