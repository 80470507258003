.banner-company {
    background-color: var(--color-primary-lighest);
    padding: 4rem 0;

    .container {
        max-width: 103rem;
        margin: 0 auto;
        box-sizing: border-box;
    }
}

.banner-company__logo {
    display: block;
    width: 26rem;
    margin: 0 3rem 2rem 0;
}

.banner-company__content {
    max-width: 63.5rem;
}

@media (--screen-large) {
    .banner-company {
        padding: 7rem 0;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}
