.page-header {
    height: 8.2rem;
    box-sizing: border-box;
    border-bottom: .1rem solid var(--color-borders);

    &.-open {
        background-color: var(--color-coal);
        border-bottom: none;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
    }

    a {
        color: var(--color-text);
        text-decoration: none;

        &:hover, &:focus {
            color: var(--color-text);
            text-decoration: underline;
        }
    }
}

@media (--screen-medium-large) {
    .page-header {
        .container {
            padding: 0 2rem;
        }
    }
}
