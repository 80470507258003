.page-header__right {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;

    & > div:not([class]) {
        display: none;
    }
}

@media (--screen-medium-large) {
    .page-header__right {
        & > div:not([class]) {
            display: flex;
            align-items: center;
            height: 100%;

            & > :nth-child(n) {
                display: inline-flex;
                align-items: center;
                height: 8.2rem;
                width: auto;
                padding: 2rem 2rem 1.7rem;
                border-left: .1rem solid var(--color-borders);
                box-sizing: border-box;
            }

            .page-header.-open & {
                display: none;
            }
        }
    }

    .page-header__favourites {
        a {
            display: block;
            position: relative;
        }
    }

    .page-header__favourites__count {
        position: absolute;
        top: -.7rem;
        right: -1rem;
        width: 2rem;
        height: 2rem;
        background-color: var(--color-primary);
        border-radius: 50%;
        text-align: center;
        font-weight: var(--font-weight-semiBold);
        font-size: 1.4rem;
        color: var(--color-white);
        letter-spacing: 0;
        line-height: 1.6;
    }

    .page-header__login {
        display: flex;

        a {
            display: flex;
            align-items: center;

            span {
                margin-left: .8rem;
            }
        }
    }
}
