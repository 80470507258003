.image-with-text {
    margin: 1rem 0 4rem;
}

.image-with-text__text {
    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
}

.image-with-text__image {
    margin-bottom: 2.4rem;
}

@media (--screen-medium-large) {
    .image-with-text {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        &.-reverse {
            .image-with-text__image {
                order: 1;
                margin: 0 0 0 3.5rem;
            }

            .image-with-text__text {
                order: 0;
            }
        }

        [data-alignment="image-text-even"] &, &.-even {
            .image-with-text__image, .image-with-text__text {
                flex: 0 0 calc(50% - 1.75rem);
            }
        }
    }

    .image-with-text__image {
        margin: 0 3.5rem 0 0;
        flex: 0 0 calc(33%);
    }

    .image-with-text__text {
        flex: 0 0 calc(66% - 3.5rem);
    }
}
