.vacancy-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    background-color: var(--color-white);
    padding: 2.5rem 2.5rem 1.5rem;

    & > :nth-child(n) {
        margin-bottom: 1rem;
    }

    & + & {
        border-top: .1rem solid var(--color-borders);
    }

    &.-expired {
        opacity: .7;
    }
}

.vacancy-edit__content {
    margin-right: 2rem;
}

.vacancy-edit__link {
    text-decoration: none;
    cursor: pointer;

    & .vacancy-edit__title > span:hover, & .vacancy-edit__title > span:focus {
        color: var(--color-primary);
    }
}

.vacancy-edit__title {
    margin: 0 0 .6rem;

    .vacancy-edit.-expired & {
        color: var(--color-gray);
    }
}

.vacancy-edit__info {
    @include no-list;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--color-gray);

    li {
        display: inline-block;
        margin-right: 2.2rem;
    }

    .icon {
        &::before {
            margin-right: .7rem;
            background-size: 1.6rem 1.6rem;
            width: 1.6rem;
            height: 1.6rem;
        }
    }
}

.vacancy-edit__actions {
    display: flex;
    align-items: center;

    & > :nth-child(n):not(:last-child) {
        margin-right: 1.4rem;
    }
}

@media (--screen-medium-large) {
    .vacancy-edit {
        padding: 3.5rem 3.5rem 2.5rem;
        flex-wrap: nowrap;
    }

    .vacancy-edit__content {
        max-width: calc(100% - 10rem);
    }
}
