.image-upload {
    display: inline-block;
    margin: 0 4rem 1rem 0;
    position: relative;
    width: 10rem;
    height: 10rem;
}

.image-upload__image {
    @include image;
}

.image-upload__input {
    cursor: pointer;
    position: absolute;
    bottom: .9rem;
    right: .9rem;
    width: 3.2rem;
    height: 3.2rem;

    input {
        cursor: pointer;
        width: 3.2rem;
        height: 3.2rem;
        opacity: 0;
    }

    &::before {
        pointer-events: none;
        content: '';
        position: absolute;
        background-color: var(--color-primary);
        background-image: url("../icons/pencil.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 1.8rem 1.8rem;
        border-radius: 50%;
        padding: .6rem;
        box-sizing: border-box;
        width: 3.2rem;
        height: 3.2rem;
    }

    &:hover, &:focus {
        cursor: pointer;

        &::before {
            background-color: var(--color-primary-hover);
        }
    }
}

@media (--screen-medium-large) {
    .image-upload {
        width: 15rem;
        height: 15rem;
    }
}
