.filter__toggle {
    position: relative;
    display: inline-flex;

    input {
        position: absolute;
        display: inline-block;
        margin: 0;
        opacity: 0;
        width: 4.2rem;

        &:checked + .filter__toggle__slider {
            background-color: var(--color-primary);

            &::before {
                transform: translateX(1.5rem);
                background-color: var(--color-white);
            }
        }
    }

    & + & {
        margin-top: 1.1rem;
    }
}

.filter__toggle__slider {
    width: 3.2rem;
    height: 1.6rem;
    position: absolute;
    cursor: pointer;
    top: 1rem;
    left: 0;
    transform: translateY(-50%);
    background-color: var(--color-white);
    border: .1rem solid rgba(0,0,0,.25);
    border-radius: 4.5rem;
    box-sizing: border-box;
    transition: all .3s ease;

    &::before {
        position: absolute;
        content: "";
        height: 1rem;
        width: 1rem;
        left: .3rem;
        bottom: .2rem;
        background-color: rgba(0,0,0,.25);
        border-radius: 50%;
        transition: all .3s ease;
    }
}

.filter__toggle__label {
    padding-left: 4.5rem;
    word-break: break-word;
}
