.form__radio {
    border: 0;
    display: none;

    + .form__label {
        position: relative;
        padding-left: 2.2rem;
        margin-bottom: .8rem;

        &::before,
        &::after {
            content: '';

            display: block;
            width: 1.6rem;
            height: 1.6rem;

            position: absolute;
            top: 0;
            left: 0;

            border-radius: 50%;
        }

        &::before {
            transition: box-shadow .2s ease,
            background-color .2s ease;
            box-shadow: inset 0 0 0 .1rem var(--color-borders);
        }

        &::after {
            transition: opacity .3s ease,
            transform .2s ease;

            z-index: 1;
            opacity: 0;
            transform: scale(1);

            width: .8rem;
            height: .8rem;

            top: .4rem;
            left: .4rem;

            background: var(--color-primary);
        }
    }

    &:checked + .form__label::before {
        box-shadow: inset 0 0 0 .1rem var(--color-primary);
    }

    &:checked + .form__label::after {
        opacity: 1;
        transform: scale(1);
    }

    &:disabled + .form__label::before, &:disabled + .form__label::after {
        opacity: .4;
    }
}

// complete profile form
.form__radio__buttons {
    + .form__fieldset {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 2rem);
        margin-left: -1rem;

        .form__label {
            flex: 1;
            white-space: nowrap;
            margin: .4rem 1rem;
        }
    }
}

.form__radio__button {
    display: none;

    + .form__label {
        @extend .button;
        background-color: var(--color-off-white);
        color: var(--color-text);

        &:hover, &:focus {
            background-color: var(--color-gray-button);
            color: var(--color-text);
        }
    }

    &.-user,
    &.-company {
        + .form__label {
            &::before {
                content: '';
                display: inline-block;
                margin: 0 0.8rem -0.2rem 0;
                background-size: 1.8rem 1.8rem;
                background-repeat: no-repeat;
                width: 1.8rem;
                height: 1.8rem;
                pointer-events: none;

                @supports (mask-repeat: no-repeat) {
                    mask-size: 1.8rem 1.8rem;
                    mask-repeat: no-repeat;
                }
            }
        }

        &:checked {
            + .form__label {
                background-color: var(--color-success);
                color: var(--color-white);
            }
        }
    }

    &.-user {
        + .form__label {
            &::before {
                background-image: url("../icons/user.svg");

                @supports (mask-repeat: no-repeat) {
                    background: var(--color-text);
                    mask-image: url("../icons/user.svg");
                }
            }
        }
    }

    &.-company {
        + .form__label {
            &::before {
                background-image: url("../icons/building.svg");

                @supports (mask-repeat: no-repeat) {
                    background: var(--color-text);
                    mask-image: url("../icons/building.svg");
                }
            }
        }
    }

    &:checked {
        + .form__label {
            background-color: var(--color-success);
            color: var(--color-white);

            &::before {
                @supports (mask-repeat: no-repeat) {
                    background: var(--color-white);
                }
            }
        }
    }
}
