.breadcrumbs {
    display: inline-block;
    @include box;
    background-color: var(--color-white);
    border-radius: .3rem;
    padding: 1.3rem 1.7rem;
    margin-bottom: 2rem;
}

.breadcrumbs__list {
    @include no-list;
}

.breadcrumbs__item {
    display: inline-block;

    &:not(:last-of-type) {
        margin-right: .6rem;
    }
}

a.breadcrumbs__item__label {
    text-decoration: none;

    &::after {
        content: '';
        display: inline-block;
        margin-left: .7rem;
        vertical-align: middle;
        background-image: url("../icons/arrow-right.svg");
        background-size: .8rem .8rem;
        width: .8rem;
        height: .8rem;
    }
}

span.breadcrumbs__item__label {
    color: var(--color-gray-medium);
}
