.form__checkbox {
    -webkit-appearance:none;
    position: relative;
    margin: .2rem .8rem 2rem 0;
    width: 1.4rem;
    height: 1.4rem;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: .2rem;
        box-sizing: border-box;
    }

    &::before {
        background-color: var(--color-white);
        border: .1rem solid var(--color-borders);
    }

    &::after {
        background-color: var(--color-primary);
        border: .1rem solid var(--color-primary);
        background-image: url("../icons/check-white.svg");
        background-size: .8rem .8rem;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0;
        transition: opacity .2s ease;
    }

    &:checked {
        &::after {
            opacity: 1;
        }
    }
}
