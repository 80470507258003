.form {
    max-width: 47rem;
    margin: 0 auto;

    &.-big {
        max-width: 69rem;
    }

    &.-full-width {
        max-width: none;
    }

    &.-center {
        text-align: center;
    }

    small {
        display: block;
        margin: 2rem 0 2.5rem;
        font-size: 1.3rem;
        color: var(--color-gray-medium);

        a {
            color: var(--color-gray-medium);
        }
    }
}

.form__intro {
    text-align: center;

    .title-3, .title-4 {
        font-weight: var(--font-weight-bold);
        margin: 0;
    }

    &.-left {
        text-align: left;
    }
}

.form__or {
    width: 100%;
    text-align: center;
    color: var(--color-text);
    border-bottom: .1rem solid var(--color-borders);
    line-height: .1;
    margin: 3rem 0 2.5rem;

    & span {
        background-color: var(--color-white);
        padding: 0 1rem;
    }

    .popup & {
        & span {
            background-color: var(--color-off-white);
        }
    }
}

.form__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;

    &.-left {
        justify-content: center;

        & > :nth-child(n):not(:last-child) {
            margin-bottom: 1.6rem;
        }

        & > :nth-child(n):last-child {
            margin-left: 1.6rem;
        }
    }
}

.form__buttons {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2rem);
    margin-left: -1rem;

    .button {
        flex: 1;
        white-space: nowrap;
        margin: .4rem 1rem;
    }

    &.-column {
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        margin-left: 0;

        .button {
            margin: 0;

            & + & {
                margin-top: 1rem;
            }
        }

        .button + .link {
            margin-top: 1.7rem;
        }
    }
}

.form__publication-date {
    margin: .8rem 0 0 .8rem;

    svg {
        @include svg;
        fill: var(--color-text);
    }
}

@media (--screen-medium-large) {
    .form__actions {
        & > :nth-child(n):not(:last-child) {
            margin-right: 2.2rem;
        }

        &.-left {
            justify-content: flex-start;

            & > :nth-child(n):not(:last-child) {
                margin-bottom: 0;
            }

            & > :nth-child(n):last-child {
                margin-left: 0;
            }
        }

        &.-no-wrap {
            flex-wrap: nowrap;
        }
    }
}
