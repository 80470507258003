.socials {
    @include no-list;
    display: flex;
    flex-wrap: wrap;
}

.socials__item {
    display: inline-block;
    margin-right: 1.6rem;
    width: 2.4rem;
    height: 2.4rem;
    background-size: 2.4rem 2.4rem;
    background-repeat: no-repeat;

    svg {
        fill: var(--color-primary);
        transition: all .3s ease;
    }

    &:hover, &:focus {
        svg {
            fill: var(--color-primary-hover);
        }
    }
}
