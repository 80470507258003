a, .link {
    color: var(--color-primary);
    text-decoration: underline;

    &:hover, &:focus {
        color: var(--color-primary-hover);
        text-decoration: none;
    }

    &.-arrow {
        text-decoration: none;

        &::after {
            content: '';
            display: inline-block;
            margin: 0 0 -.1rem .4rem;
            background-image: url("../icons/arrow.svg");
            background-size: 1.3rem 1.3rem;
            width: 1.3rem;
            height: 1.3rem;
            transition: all .3s ease;
        }

        &:hover, &:focus {
            &::after {
                margin-left: .8rem;
                margin-right: -.4rem;
            }
        }
    }

    &.-alert {
        font-size: 1.6rem;
        text-decoration: none;
        text-align: left;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: text-top;
            margin-right: 1.1rem;
            background-image: url("../icons/bell.svg");
            background-size: 2rem 2rem;
            width: 2rem;
            height: 2rem;
            transition: all .3s ease;
        }
    }
}

// Only apply if there is a class 'link'
.link {
    &:hover, &:focus {
        cursor: pointer;
    }

    &.-gray {
        color: var(--color-text);
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }

        .form__entry & {
            display: block;
            margin-top: .5rem;
            font-weight: var(--font-weight-semiBold);
            font-size: 1.4rem;
            color: var(--color-text-light);
        }
    }

    &.-small {
        font-size: 1.3rem;
    }
}
