.filter__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--color-gray-button);
    padding: 1rem;
    border-radius: .4rem;
    box-sizing: border-box;
    font-size: 1.8rem;

    &::after {
        content: '';
        display: inline-block;
        background-image: url("../icons/select.svg");
        background-position: right;
        background-repeat: no-repeat;
        width: 1.2rem;
        height: .8rem;
        transition: all .3s ease;
    }

    &.-open {
        &::after {
            transform: rotate(180deg);
        }
    }
}

.filter__form {
    display: none;

    .filter.-open & {
        display: block;
    }

    .form__entry {
        margin-bottom: 3rem;

        & .button {
            margin: 1rem 0 1.3rem;
        }
    }

    .form__label {
        margin-top: 0;
        font-size: 2rem;
        color: var(--color-gray-medium);
        line-height: 1.5;
    }
}

.filter__tags {
    margin-top: .8rem;
}

.filter__toggles {
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem;
}

@media (--screen-medium-large) {
    .filter {
        width: 24.6rem;
        margin-right: 4rem;
    }

    .filter__title {
        display: none;
    }

    .filter__form {
        display: block;

        .form__label {
            font-size: 2.4rem;
        }
    }
}
