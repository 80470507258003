.form__message {
    color: var(--color-danger);
    font-size: 1.3rem;
    display: block;
    text-align: left;
    margin-top: .6rem;
    box-sizing: border-box;
    order: 10;

    .form__entry.-checkbox & {
        margin-bottom: 2rem;
    }
}
