.vacancy {
    display: block;
    position: relative;
    width: 100%;
    padding: 1.8rem 1.5rem;
    box-sizing: border-box;

    text-decoration: none;

    &.-premium {
        padding: 3.6rem 1.5rem 1.8rem;
        background: var(--color-secondary-lighest);
        border: .2rem solid var(--color-orange);

        & + & {
            border-top: none;
        }
    }
}

.vacancy__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3rem 1rem 0;
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: .2rem;
    box-sizing: border-box;

    .vacancy.-no-logo & {
        display: none;
    }
}

.vacancy__tag {
    position: absolute;
    top: 1.1rem;
    right: .9rem;
}

.vacancy__tag__item {
    background-color: var(--color-orange);
    padding: .2rem .8rem;
    border-radius: .4rem;

    font-size: 1.3rem;
    color: var(--color-white);
    line-height: 1.54;

    &.-new {
        background-color: var(--color-primary);
        color: var(--color-white);
    }
}

.vacancy__content {
    margin: 0 2rem 0 0;
    color: var(--color-gray);
    max-width: 83rem;
}

.vacancy__content__title {
    display: block;
    font-size: 1.8rem;
    color: var(--color-gray-medium);
}

.vacancy__content__info {
    display: inline-block;
    margin-right: 2.2rem;

    &::before {
        content: '';
        display: inline-block;
        @include svg;
        background: var(--color-black);
        mask-size: 1.6rem 1.6rem;
        width: 1.6rem;
        height: 1.6rem;
    }

    &.-company {
        &::before {
            mask-image: url("../icons/company.svg");
        }
    }

    &.-location {
        &::before {
            mask-image: url("../icons/location.svg");
        }
    }

    &.-employment {
        &::before {
            mask-image: url("../icons/job.svg");
        }
    }

    &.-publicationDate {
        &::before {
            mask-image: url("../icons/time.svg");
        }
    }
}

.vacancy__right {
    display: flex;
    align-items: center;
    min-width: 3.8rem;
    margin: 1rem 0 0 auto;
}

@media (--screen-medium-large) {
    .vacancy {
        display: flex;
        padding: 3.8rem 3.5rem;

        &.-premium {
            padding: 3.8rem 3.5rem;
        }
    }

    .vacancy__content__title {
        font-size: 2rem;
    }

    .vacancy__right {
        margin: 0 0 0 auto;
    }
}
