.ckeditor {
    margin-top: 1.5rem;

    .ck-content {
        min-height: 20rem;
    }
}

@media (--screen-medium-large) {
    .ckeditor {
        .ck-content {
            min-height: 40rem;
        }
    }
}
