.button.-bell {
    position: relative;
    padding: .9rem 1.2rem .9rem 5.5rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--color-primary-light);
        width: 4rem;
        height: 100%;
        border-radius: .4rem 0 0 .4rem;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 1.1rem;
        transform: translateY(-50%);
        margin: 0;
    }

    &:hover, &:focus {
        background-color: var(--color-primary-light);
    }

    .page-head.-company & {
        padding: .2rem 1rem .1rem 3.5rem;

        &::before {
            width: 2.5rem;
            height: 2.5rem;
        }

        svg {
            width: 1.4rem;
            height: 1.4rem;
            left: .6rem;
        }
    }
}
