.form__entry.-upload {
    display: inline-flex;
    margin-bottom: 0;
}

.form__upload {
    p {
        margin: 0 0 2rem;
    }
}

.form__upload__file {
    display: block;
    width: 100%;
    height: 5rem;
    padding: 1.5rem 0 1.5rem 15rem;
    box-sizing: border-box;
    position: relative;
    outline: none;

    color: var(--color-label);
    font-size: 1.6rem;
}

.form__upload__file[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    width: 0;
}

.form__upload__button {
    position: absolute;
}

// Firefox fallback
@-moz-document url-prefix() {
    input[type=file]::file-selector-button {
        display: none;
    }

    .form__upload__file {
        padding-left: 17rem;

        @media (--screen-medium-large) {
            padding-left: 22rem;
        }
    }
}

@media (--screen-medium-large) {
    .form__entry.-upload {
        display: inline-block;
    }
}
