.scrolling-table {
    margin: 0 0 3.2rem;
    max-width: 100%;
    overflow: auto;
}

.scrolling-table__table {
    min-width: 68rem;

    table {
        margin: 0;
    }
}
