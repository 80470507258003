.container {
    width: 100%;
    max-width: 130rem;
    margin: 0 auto;
    padding: 0 2rem;
    box-sizing: border-box;

    &.-small {
        max-width: 116rem;
    }

    &.-flex {
        display: flex;
        flex-direction: column;

        div {
            width: 100%;
        }
    }

    &.-flex-reverse {
        display: flex;
        flex-direction: column-reverse;

        div:not(.job-cards) {
            width: 100%;
        }
    }

    &.-pt {
        padding-top: 4rem;
    }

    &.-center {
        text-align: center;
    }

    &.-text {
        max-width: 50rem;
    }
}

@media (--screen-medium-large) {
    .container {
        padding: 0 5rem;

        &.-flex,
        &.-flex-reverse {
            flex-direction: row;
            justify-content: space-between;
        }

        &.-pt {
            padding-top: 8rem;
        }
    }
}
