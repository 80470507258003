.form__entry {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &.-checkbox,
    &.-radio {
        flex-direction: row;
        margin-top: 2rem;

        & + .form__entry.-checkbox,
        & + .form__entry.-radio {
            margin-top: 1.6rem;
        }
    }

    &.-upload {
        margin-bottom: 3rem;
    }

    & + .button[type="submit"] {
        margin-top: 2.6rem;
    }

    .popup & {
        margin-bottom: 2rem;
    }
}
