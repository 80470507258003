main {
    padding-bottom: 5rem;

    body[type="homepage"] &,
    body[type="detailpage"] & {
        padding-bottom: 0;
    }
}

@media (--screen-medium-large) {
    main {
        padding-bottom: 9rem;
    }
}
