.vacancy-button-old {
    position: relative;
    padding: 0;
    cursor: pointer;

    background-color: var(--color-gray-button);
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        fill: var(--color-gray-button-dark);
        transition: fill .2s ease;
    }

    &:hover, &:focus {
        svg {
            fill: var(--color-black);
        }
    }

    &.-favourite {
        background-color: var(--color-yellow);

        svg {
            fill: var(--color-white);
        }

        &::after {
            content: '';
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url("../icons/delete.svg");
            background-size: 3.8rem 3.8rem;
            background-repeat: no-repeat;
            width: 3.8rem;
            height: 3.8rem;
            opacity: 0;
            transition: opacity .3s ease;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }
}
.vacancy-button {
    position: relative;
    padding: 0;
    cursor: pointer;

    background-color: var(--color-gray-button);
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        fill: var(--color-gray-button-dark);
        transition: fill .2s ease;
    }

    &:hover, &:focus {
        svg {
            fill: var(--color-black);
        }
    }

    &.-favourite {
        background-color: var(--color-yellow);

        svg {
            fill: var(--color-white);
        }

        &::after {
            content: '';
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url("../icons/delete.svg");
            background-size: 3.8rem 3.8rem;
            background-repeat: no-repeat;
            width: 3.8rem;
            height: 3.8rem;
            opacity: 0;
            transition: opacity .3s ease;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }
}

.vacancy-button__tooltip {
    position: absolute;
    left: 5rem;

    background-color: var(--color-coal);
    border-radius: .3rem;
    padding: .9rem;

    font-size: 1.6rem;
    color: var(--color-white);
    text-align: center;

    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;

    &::after {
        content: '';
        position: absolute;
        left: -.5rem;
        transform: rotate(180deg) translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: .5rem 0 .5rem .6rem;
        border-color: transparent transparent transparent var(--color-coal);
    }

    .vacancy-button-old.-favourite:hover & {
        opacity: 1;
        visibility: visible;
    }
    .vacancy-button.-favourite:hover & {
        opacity: 1;
        visibility: visible;
    }
}

@media (--screen-medium-large) {
    .vacancy-button__tooltip {
        left: auto;
        right: 5rem;

        &::after {
            top: 50%;
            left: auto;
            right: -.5rem;
            transform: translateY(-50%);
        }
    }
}
