.badge {
    display: inline-block;
    background-color: var(--color-primary);
    border-radius: .4rem;
    padding: .25rem .8rem;
    box-sizing: border-box;

    font-size: 1.2rem;
    color: var(--color-white);
    text-align: center;
    font-style: normal;
    text-transform: capitalize;

    .card & {
        margin-left: 1.7rem;
    }

    &.-premium {
        background-color: var(--color-orange);
        color: var(--color-white);
    }

    &.-online,
    &.-offline,
    &.-draft,
    &.-expired {
        &::before {
            content: '';
            display: inline-block;
            vertical-align: text-top;
            margin: 0 .5rem -.2rem 0;
            background-size: 1.6rem 1.6rem;
            background-repeat: no-repeat;
            background-position: center center;
            width: 1.6rem;
            height: 1.6rem;
        }
    }

    &.-online {
        background-color: var(--color-success-30);
        color: var(--color-orange);

        &::before {
            vertical-align: baseline;
            margin-bottom: 0;
            background-image: url("../icons/check.svg");
            background-size: 1.4rem 1.1rem;
            width: 1.4rem;
            height: 1.1rem;
        }
    }

    &.-offline {
        background-color: var(--color-warning);
        color: var(--color-gray-medium);

        &::before {
            background-image: url("../icons/view.svg");
        }
    }

    &.-draft {
        background-color: var(--color-gray);
        color: var(--color-white);

        &::before {
            background-image: url("../icons/draft.svg");
            background-size: 1.4rem 1.4rem;
        }
    }

    &.-expired {
        background-color: var(--color-danger-30);
        color: var(--color-red);

        &::before {
            background-image: url("../icons/error.svg");
            background-size: 1rem 1rem;
        }
    }
}
