.call-to-action {
    margin: 2rem 0;
    padding: 4rem 0;
    background-color: var(--color-primary-lighest);

    .container {
        display: flex;
        align-self: center;
        flex-wrap: wrap;
    }

    img {
        display: block;
        width: 29rem;
        height: 100%;
        object-fit: cover;
        margin: 0 4.5rem 2rem 0;
    }
}

.call-to-action__content {
    max-width: 69rem;
}

.call-to-action__title {
    line-height: 1.35;
    max-width: 50rem;
    margin-top: 0;
}

.call-to-action__text {
    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
}

.call-to-action__actions {
    margin-top: 2.4rem;
}

@media (--screen-medium-large) {
    .call-to-action__content {
        font-size: 2rem;
    }
}

@media (--screen-large) {
    .call-to-action {
        margin: 4rem 0;
        padding: 14rem 0 12.5rem;

        .container {
            flex-wrap: nowrap;
        }
    }
}
