.page-header__user__image {
    position: relative;
    display: inline-block;

    img {
        display: inline-block;
        width: 4.4rem;
        height: 4.4rem;
        object-fit: cover;
        border-radius: 50%;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: -.3rem;
        background-image: url("../icons/online.svg");
        background-repeat: no-repeat;
        background-size: 2rem 2rem;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
    }

    &.-login {
        &::before {
            content: '';
            position: absolute;
            top: 1rem;
            left: -1rem;
            background-image: url("../icons/lock.svg");
            background-size: 1.7rem 1.7rem;
            width: 1.7rem;
            height: 1.7rem;
        }
    }

    & + div {
        margin-left: 1.6rem;
    }
}

.page-header__user__panel {
    font-weight: var(--font-weight-bold);
    position: relative;
    z-index: 1;

    ul {
        @include no-list;
        padding: 2.8rem;
        border-top: .1rem solid var(--color-borders);
    }

    svg {
        fill: var(--color-text);
        display: inline-block;
        margin-bottom: -.2rem;
    }
}

.page-header__user__login {
    display: flex;
    padding: 2.8rem;

    strong {
        display: block;
        color: var(--color-gray-medium)
    }
}
