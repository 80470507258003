h1,
.title-1,
h2,
.title-2,
h3,
.title-3,
h4,
.title-4,
h5,
.title-5,
h6,
.title-6 {
    margin: 1rem 0 2rem;
    font-weight: normal;
    line-height: 1.2;
    color: var(--color-gray-medium);
}

h1, .title-1 {
    margin-top: 0;
    font-size: 2.8rem;
}

h2, .title-2 {
    font-size: 2.8rem;
}

h3, .title-3 {
    font-size: 2.4rem;
}

h4, .title-4 {
    font-size: 2rem;
}

h5, .title-5 {
    font-size: 1.8rem;
}

h6, .title-6 {
    font-size: 1.5rem;
}

div[data-block-type="heading-5"][data-color="primary"] {
    h6 {
        color: var(--color-primary);
    }
}

@media (--screen-medium-large) {
    h1, .title-1 {
        font-size: 3.2rem;
    }

    h2, .title-2 {
        margin-bottom: 3rem;
        font-size: 3.2rem;
    }

    h3, .title-3 {
        margin-bottom: 1.6rem;
        font-size: 2.8rem;
    }

    h4, .title-4 {
        margin-bottom: 1.6rem;
        font-size: 2.4rem;
    }

    h5, .title-5 {
        margin-bottom: 1.8rem;
        font-size: 2rem;
    }

    h6, .title-6 {
        margin-bottom: 1.7rem;
        font-size: 1.8rem;
    }
}
