.vacancy-cards {
    width: 100%;
    max-width: 77.4rem;
    margin: 0 0 0 auto;
}

.vacancy-cards__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: .6rem;

    h1 {
        margin: 0 2rem 1rem 0;
    }

    .link.-alert {
        margin-bottom: 1rem;
    }
}
