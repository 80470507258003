p {
    margin: 1rem 0 3.2rem;

    div[data-block-type="paragraph"][data-styling="small"] & {
        font-size: 1.3rem;
    }

    div[data-block-type="paragraph"][data-styling="center"] & {
        text-align: center;
    }

    div[data-block-type="paragraph"][data-styling="small-center"] & {
        font-size: 1.3rem;
        text-align: center;
    }

    &.-green {
        color: var(--color-orange);
    }

    &.red {
        color: var(--color-red);
    }

    & > img:not([class]) {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 3.2rem;
    }
}
