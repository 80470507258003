.account-overview {
    margin-bottom: 6rem;

    .title-4 {
        text-align: left;
        font-weight: var(--font-weight-bold);
        border-bottom: .1rem solid var(--color-gray);
    }

    &.-hide {
        display: none;
    }
}

.account-overview__overview {
    width: 100%;

    tr > td {
        border: none;
        font-size: 1.6rem;
        text-align: right;
        white-space: nowrap;

        &:last-of-type {
            width: 20%;
        }
    }

    tr:first-of-type > td:first-of-type {
        text-align: left;
    }
}
