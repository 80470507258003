.job-cards {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2rem);
    margin-left: -1rem;
}

.job-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0 .5rem 1rem;
    background-color: var(--color-white);
    box-shadow: 0 .2rem 1rem 0 rgba(229, 242, 255, .2), 0 .2rem 1rem 0 rgba(0, 0, 0, .17);
    border-radius: 1rem;
    text-decoration: none;
}

.job-card__top {
    padding: 2.6rem 2.6rem 1.6rem;

    strong {
        display: block;
        font-size: 1.8rem;
        margin: 1rem 0 0;
        word-break: break-all;
    }
}

.job-card__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5.2rem;
    height: 5.2rem;
    margin-right: 2rem;
    box-sizing: border-box;

    img {
        display: block;
        width: 100%;
    }
}

.job-card__bottom {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--color-off-white);
    padding: 2.1rem 2.6rem 1.6rem;
}

.job-card__info {
    display: inline-block;
    margin: 0 1rem .5rem 0;

    font-size: 1.4rem;
    color: var(--color-text);
    font-style: normal;

    &.icon {
        &::before {
            margin-right: .4rem;
        }
    }
}

@media (--screen-large) {
    .job-card {
        max-width: calc(50% - 1rem);
    }
}

@media only screen and (min-width: 930px) {
    .job-card {
        span {
            display: flex;
        }
    }

    .job-card__top {
        strong {
            display: inline-block;
            margin: 0;
        }
    }
}
