.alert {
    position: relative;
    margin: 2rem 0 2.5rem;

    background-color: var(--color-blue-light);
    padding: 1.5rem 3rem 1.5rem 4.5rem;
    box-sizing: border-box;
    border-radius: .4rem;
    color: var(--color-blue);

    &::before {
        content: '';
        position: absolute;
        top: 1.8rem;
        left: 1.6rem;
        display: block;
        margin-right: 1.1rem;
        background-image: url("../icons/alert.svg");
        background-size: 1.8rem 1.8rem;
        width: 1.8rem;
        height: 1.8rem;
    }

    p {
        margin: 0;
    }
}

@media (--screen-medium-large) {
    .alert {
        margin: 4rem 0 4.5rem;
    }
}
