.page-header__left {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
}

.page-header__logo {
    display: inline-block;
    height: 100%;
    padding: 2rem 2rem 1.7rem 0;
    box-sizing: border-box;

    img {
        display: block;
        width: 12.3rem;
        height: 3.8rem;
    }

    .page-header.-open & {
        display: none;
    }
}

.page-header__nav {
    display: none;
    padding: 2rem 0 1.7rem;
    border-left: .1rem solid var(--color-borders);

    & > :nth-child(n) {
        margin: 0 2rem;
    }
}

@media only screen and (min-width: 930px) {
    .page-header__nav {
        display: flex;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
    }
}
