.page-head {
    padding: 4rem 0;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
    }

    h1 {
        margin-right: 2rem;
    }

    &.-vacancy, &.-company, &.-profile {
        position: relative;
        //background-image: url("../images/page-head-vacancy.jpeg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-image: linear-gradient(270deg, rgba(248, 248, 248, .2) 5%, #F6F6F6 100%);
            background-size: cover;
            width: 100%;
            height: 100%;
        }

        .container {
            justify-content: flex-start;
        }
    }

    &.-profile {
        background-image: url("../images/page-head-profile.jpg");
    }
}

.page-head__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.page-head__logo {
    @include box;
    background-color: var(--color-white);
    width: 8rem;
    height: 8rem;
    padding: .5rem;
    box-sizing: border-box;
    margin: 0 3.7rem 1rem 0;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        display: block;
        width: 100%;
    }

    .page-head.-profile & {
        box-shadow: none;
        border-radius: 50%;

        img {
            border-radius: 50%;
        }
    }
}

.page-head__content {
    max-width: 48.4rem;
    color: var(--color-gray);

    h1 {
        font-size: 2.6rem;
        line-height: 1.38;
        margin: 0;
        word-break: break-word;

        & + p {
            margin-top: 2.7rem;
        }
    }

    .title-5 {
        font-size: 2rem;
        color: var(--color-text);
        margin: 0 0 2rem;
    }

    .page-head.-profile & {
        max-width: none;
        flex: 1;
    }
}

.page-head__content__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.page-head__content__info {
    margin-right: 2.2rem;
    white-space: nowrap;
    color: var(--color-gray);
    text-decoration: none;

    &[href]:hover, &[href]:focus {
        color: var(--color-gray);
        text-decoration: underline;
    }

    &::before {
        content: '';
        display: inline-block;
        @include svg;
        background-size: 1.6rem 1.6rem;
        width: 1.6rem;
        height: 1.6rem;
    }

    &.-company {
        &::before {
            background-image: url("../icons/company.svg");
        }
    }

    &.-location {
        &::before {
            background-image: url("../icons/location.svg");
        }
    }

    &.-email {
        &::before {
            background-image: url("../icons/email.svg");
        }
    }

    &.-phone {
        &::before {
            background-image: url("../icons/phone.svg");
        }
    }
}

@media (--screen-medium-large) {
    .page-head {
        padding: 7rem 0 6rem;
    }

    .page-head__logo {
        width: 11rem;
        height: 11rem;
        padding: 1rem;
    }
}

@media (--screen-large) {
    .page-head__logo {
        width: 14rem;
        height: 14rem;
    }
}
