/* Nunito-Regular */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: var(--font-weight-regular);
    src: url('fontfiles/Nunito-Regular.ttf') format('truetype');
}

/* Nunito-Italic */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: var(--font-weight-regular);
    src: url('fontfiles/Nunito-Italic.ttf') format('truetype');
}

/* Nunito-SemiBold */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: var(--font-weight-semiBold);
    src: url('fontfiles/Nunito-SemiBold.ttf') format('truetype');
}

/* Nunito-SemiBoldItalic */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: var(--font-weight-semiBold);
    src: url('fontfiles/Nunito-SemiBoldItalic.ttf') format('truetype');
}

/* Nunito-Bold */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: var(--font-weight-bold);
    src: url('fontfiles/Nunito-Bold.ttf') format('truetype');
}

/* Nunito-BoldItalic */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: var(--font-weight-bold);
    src: url('fontfiles/Nunito-BoldItalic.ttf') format('truetype');
}
