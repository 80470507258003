.icon {
    display: inline-block;

    &::before {
        content: '';
        display: inline-block;
        margin: 0 .8rem -.1rem 0;
        background-repeat: no-repeat;
        background-size: 1.3rem 1.3rem;
        width: 1.3rem;
        height: 1.3rem;
    }

    &.-email {
        &::before {
            background-image: url("../icons/email.svg");
            background-size: 2rem 1.6rem;
            width: 2rem;
            height: 1.6rem;
        }
    }

    &.-phone {
        &::before {
            background-image: url("../icons/phone.svg");
            background-size: 1.9rem 1.9rem;
            width: 1.9rem;
            height: 1.9rem;
        }
    }

    &.-close {
        &::before {
            background-image: url("../icons/close.svg");
            background-size: 1rem 1rem;
            width: 1rem;
            height: 1rem;
        }
    }

    &.-location {
        &::before {
            background-image: url("../icons/location.svg");
        }
    }

    &.-employment {
        &::before {
            background-image: url("../icons/job.svg");
        }
    }

    &.-publicationDate {
        &::before {
            background-image: url("../icons/time.svg");
        }
    }

    &.-category {
        &::before {
            background-image: url("../icons/category.svg");
        }
    }

    &.-views {
        &::before {
            background-image: url("../icons/views.svg");
        }
    }

    &.-clicks {
        &::before {
            background-image: url("../icons/click.svg");
        }
    }

    &.-date {
        &::before {
            background-image: url("../icons/date.svg");

            @supports (mask-repeat: no-repeat) {
                background: var(--color-gray);
                mask-image: url("../icons/date.svg");
                mask-size: 1.3rem 1.3rem;
                mask-repeat: no-repeat;
            }
        }
    }
}
