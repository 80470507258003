.pagination {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    height: 4rem;
    width: 100%;
    margin: 3rem 0;

    color: var(--color-primary);
    font-size: 1.6rem;

    a {
        color: var(--color-primary);
        text-decoration: none;

        &[href]:hover, &[href]:focus, &[href]:active {
            border-color: var(--color-primary-hover);;
            background-color: var(--color-primary-hover);
            color: var(--color-white);
        }
    }
}

.pagination__summary {
    width: auto;
    padding: 0 .5rem;
    margin: 0;
    align-self: center;

    text-align: center;
}

.pagination__list {
    display: none;
    align-items: stretch;
    margin: 0;
    padding: 0;

    list-style: none;
}

.pagination__previous, .pagination__next {
    display: flex;
    align-items: center;
    padding: 0 1.3rem;

    border: .1rem solid var(--color-borders);

    &:not([href]) {
        color: var(--color-grayscale-1);
    }
}

.pagination__previous, .pagination__next {
    span {
        display: none;
    }
}

.pagination__previous {
    padding: 0 1.3rem 0 1.2rem;
}

.pagination__next {
    padding: 0 1.2rem 0 1.3rem;
    margin-left: -.1rem;
}

.pagination__item {
    display: flex;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* stylelint-disable selector-class-pattern */
.pagination__item-link, .pagination__item__spacer {
    display: inline-block;
    box-sizing: border-box;
    margin-left: -.1rem;
    width: 4rem;

    text-align: center;
    line-height: 4rem;

    border: .1rem solid var(--color-borders);

    .pagination__item.-current & {
        border-color: var(--color-primary);
        background-color: var(--color-primary);
        color: var(--color-white);
    }
}
/* stylelint-enable selector-class-pattern */

@media (--screen-medium-large) {
    .pagination {
        width: auto;
        justify-content: center;
        margin: 6rem 0;
    }

    .pagination__summary {
        display: none;
    }

    .pagination__list {
        display: flex;
    }

    .pagination__previous, .pagination__next {
        font: 0/0 sans-serif; // hide arrows

        span {
            display: inline-block;
            font-size: 1.6rem;
        }
    }

    .pagination__previous {
        border-radius: .4rem 0 0 .4rem;
    }

    .pagination__next {
        border-radius: 0 .4rem .4rem 0;
    }
}
