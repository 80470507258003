.admin {
    background-color: var(--color-white);
    padding: 3rem 2.9rem 2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & > :nth-child(n) {
        margin-bottom: 1rem;
    }

    & + & {
        border-top: .1rem solid var(--color-borders);
    }
}

.admin__email {
    color: var(--color-gray);

    svg {
        @include svg;
        fill: var(--color-gray);
    }

    span {
        margin-right: 2.8rem;
    }
}

.admin__email__invitation {
    white-space: nowrap;
    margin-right: 2rem;
    font-size: 1.3rem;
    color: var(--color-text);
}

.admin__actions {
    display: flex;
    align-items: center;

    & > :nth-child(n):not(:last-child) {
        margin-right: 1.4rem;
    }
}
