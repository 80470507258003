.navigation {
    display: none;
    background-color: var(--color-coal);
    overflow: auto;

    .page-header.-open + & {
        display: block;
        height: calc(100% - 8.2rem);
    }

    & > :nth-child(n) {
        padding: 2rem 3.5rem 4.35rem;
    }

    a:not(.dropdown__link) {
        color: var(--color-white);
        font-size: 2rem;
        text-decoration: none;
    }

    svg {
        fill: var(--color-white);
        @include svg;
    }
}

.navigation__nav {
    ul:not([class]) {
        @include no-list;

        li {
            margin-bottom: 2.5rem;
        }
    }
}

.navigation__foldout {
    border-top: .1rem solid var(--color-gray);

    details {
        ul {
            @include no-list;

            a {
                font-size: 1.7rem;
                line-height: 1.8;
            }
        }

        &[open] {
            summary {
                margin-bottom: 1.5rem;
            }
        }
    }

    summary {
        color: var(--color-gray);
        font-weight: var(--font-weight-bold);
        display: flex;
        justify-content: space-between;

        &::marker {
            font-size: 0; // hide marker
        }

        &::-webkit-details-marker {
            display: none; // hide marker safari
        }

        &::after {
            content: '';
            display: inline-block;
            background-image: url("../icons/select.svg");
            background-repeat: no-repeat;
            background-size: 1.2rem .8rem;
            width: 1.2rem;
            height: .8rem;
        }
    }
}
