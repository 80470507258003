.button.-heart {
    position: relative;
    padding: .9rem 1.2rem .9rem 5.5rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--color-primary-light);
        background-image: url("../icons/heart.svg");
        background-size: 2.3rem 2.3rem;
        background-repeat: no-repeat;
        background-position: center center;
        width: 4rem;
        height: 100%;
        border-radius: .4rem 0 0 .4rem;
    }

    &.-favourite {
        &::before {
            background-image: url("../icons/heart-full.svg");
        }
    }

    &:hover, &:focus {
        background-color: var(--color-primary-light);
    }
}
