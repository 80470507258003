%form__field-reset {
    transition: all .2s ease;
    box-sizing: border-box;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS

    display: inline-block;
    width: 100%;
    margin: 0;
    padding: .7rem 1.6rem;
    vertical-align: baseline;

    font-size: 1.5rem;
    line-height: 1.6;

    color: var(--color-text);
    background-color: var(--color-white);
    border: .1rem solid var(--color-borders);
    border-radius: .4rem;

    // IOS fallback
    min-height: 4rem;

    // Firefox outline style
    outline: none;
}

// FF cursor pointer bug fix
input {
    cursor: pointer;
}

.form__field {
    @extend %form__field-reset;

    &[type="email"]:not(.-simple),
    &.-password,
    &[type="date"],
    &.-link {
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 4rem 4rem;
        padding-left: 5.1rem;
    }

    &[type="email"]:not(.-simple) {
       background-image: url("../icons/input-email.svg");
    }

    &.-password {
       background-image: url("../icons/input-password.svg");
    }

    &[type="date"]{
        -webkit-appearance: none;
        font-family: var(--font-family);
        background-image: url("../icons/input-date.svg");

        &::-webkit-calendar-picker-indicator {
            position: absolute;
            left: -1rem;
            opacity: 0;
        }
    }

    &.-link {
        background-image: url("../icons/input-link.svg");
    }

    &:focus {
        outline: none;
    }

    &.-textarea {
        resize: vertical;
    }

    .form__entry.-invalid & {
        border: .2rem solid var(--color-danger);

        // Firefox outline style & shadow
        outline: none;
        box-shadow: none;
    }

    .filter & + .form__select {
        margin-top: 1.6rem;
    }

    &:disabled {
        cursor: default;
        color: var(--color-text-light);
    }
}

.form__field__passwordcontainer {
    position: relative;
}

.form__field__passwordtoggle {
    position: absolute;
    top: 1.05rem;
    right: 1.5rem;
    display: inline-block;
    border: 0;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer;

    &.-active {
        svg {
            opacity: .5;
        }
    }
}
