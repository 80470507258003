@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 1.2rem !default;
$hamburger-padding-y: 1.2rem !default;
$hamburger-layer-width: 2.1rem !default;
$hamburger-layer-height: .2rem !default;
$hamburger-layer-spacing: .5rem !default;
$hamburger-layer-color: var(--color-gray-dark) !default;
$hamburger-layer-border-radius: .4rem !default;
$hamburger-hover-opacity: .7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Hamburger
// ==================================================
.page-header__hamburger-wrapper {
    padding: 2rem 0 1.7rem 2.4rem;
    border-left: .1rem solid var(--color-borders);

    .page-header.-open & {
        border-left: none;
    }
}

.page-header__hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition-property: background-color, opacity, filter;
    transition-duration: .15s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: var(--color-gray-button);
    border-radius: .5rem;
    width: 4.5rem;
    height: 4.5rem;
    border: 0;
    margin: 0;
    outline: none;
}

.page-header__hamburger__box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.page-header__hamburger__box-inner {
    display: block;
    top: auto;
    bottom: 0;
    transition-duration: .13s;
    transition-delay: .13s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19);
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition: transform .15s ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        transition: top .12s .2s cubic-bezier(.33333, .66667, .66667, 1),
        transform .13s cubic-bezier(.55, .055, .675, .19);
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
        transition: top .2s .2s cubic-bezier(.33333, .66667, .66667, 1),
        opacity .1s linear;
    }

    .page-header__hamburger.-active & {
        transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(-45deg);
        transition-delay: .22s;
        transition-timing-function: cubic-bezier(.215, .61, .355, 1);

        &::after {
            top: 0;
            opacity: 0;
            transition: top .2s cubic-bezier(.33333, 0, .66667, .33333),
            opacity .1s .22s linear;
        }

        &::before {
            top: 0;
            transform: rotate(-90deg);
            transition: top .1s .16s cubic-bezier(.33333, 0, .66667, .33333),
            transform .13s .25s cubic-bezier(.215, .61, .355, 1);
        }
    }
}

@media only screen and (min-width: 930px) {
    .page-header__hamburger-wrapper,
    .page-header__hamburger {
        display: none;
    }
}
