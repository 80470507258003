.vacancies {
    padding: 4rem 0;

    .container {
        display: flex;
        flex-direction: column;
    }

    .button {
        align-self: center;
    }
}

.vacancies__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    h2 {
        margin: 0 2rem 0 0;
    }

    .link.-arrow {
        margin: 1rem 0;
    }
}

.vacancies__list {
    @include no-list;
    @include box;
    margin: 3rem 0;

    & :nth-child(even) a:not(.-premium) {
        background-color: #FAFAFA;
    }
}

.vacancy__list__item {
    &.-premium {
        & + & .vacancy {
            border-top: none;
        }
    }
}

@media (--screen-medium-large) {
    .vacancies {
        padding: 5rem 0;
    }

    .vacancies__list {
        margin: 3rem 0 5.5rem;
    }
}
