.row {
    margin: 0;
    padding: 0 2rem;

    &.-size-full-width,
    .container  & {
        padding: 0;
    }
}

.row__sizer {
    width: 114rem;
    max-width: 100%;
    margin: 0 auto;

    .row.-size-full-width & {
        width: 100%;
    }
}

.row__inner {
    width: 68.8rem;
    max-width: 100%;
    margin: 0 auto;

    .pagecontent-row-container.-align-left &, .row.-align-left & {
        margin-left: 0;
    }

    .row.-size-wide &,
    .row.-size-full-width &,
    .row-wrapper.-wide & {
        width: 100%;
    }
}
