table {
    table-layout: fixed;
    width: 100%;
    margin: 0 0 3.2rem;
    border-collapse: collapse;

    font-size: 1.3rem;
    line-height: 1.4;

    th, td {
        padding: .8rem .7rem;
        border: .1rem solid var(--color-borders);
        vertical-align: top;
    }

    th {
        font-weight: bold;
        background-color: #F2F2F2;
        vertical-align: middle;
    }
}
