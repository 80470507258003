.price-plans {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.price-plan {
    background-color: var(--color-blue-background);
    border-radius: 1rem;
    padding: 2.5rem;
    max-width: 35rem;
    box-sizing: border-box;
    margin-bottom: 4rem;
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        box-shadow: 0 2px 50px 0 rgba(229, 242, 255, 0.51), 0 2px 50px 0 rgba(0, 0, 0, 0.30);
        width: 80%;
        height: 25.5rem;
    }

    &.-premium {
        background-color: var(--color-secondary-lighest);

        .button {
            background-color: var(--color-secondary);
            color: var(--color-black);

            &:hover, &:focus {
                background-color: var(--color-secondary-hover);
            }
        }
    }
}

.price-plan__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.45rem;
    border-bottom: .1rem solid var(--color-blue-background-2);
    color: var(--color-gray-medium);

    div {
        display: flex;
        flex-direction: column;

        &:first-of-type {
            margin-right: 2rem;
        }
    }

    .price-plan.-premium  & {
        border-bottom: .1rem solid var(--color-orange-light);
    }
}

.price-plan__top__title {
    font-weight: var(--font-weight-bold);
    font-size: 2rem;
    margin: 0;
}

.price-plan__top__subtitle {
    font-size: 1.3rem;
    line-height: 1.5;
}

.price-plan__top__price {
    text-align: center;
    background-color: var(--color-blue-background-2);
    border-radius: 1rem;
    padding: .6rem 1.2rem;

    font-size: 1.3rem;
    color: var(--color-gray-medium);

    strong {
        color: var(--color-gray-medium);
        font-weight: var(--font-weight-regular);
        font-size: 3.2rem;
        margin-bottom: -.6rem;
    }

    .price-plan.-premium & {
        background-color: var(--color-orange-light);
        color: var(--color-text);
    }
}

.price-plan__info {
    @include no-list;
    margin: 2.5rem 0;
    font-size: 1.6rem;

    & > li {
        margin-bottom: 1.6rem;
    }
}

.price-plan__info__item {
    display: flex;
    align-items: flex-start;

    span {
        position: relative;
        padding-left: 3rem;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            display: inline-block;
            margin-right: .8rem;
            background-position: center center;
            background-repeat: no-repeat;
            width: 2.4rem;
            height: 2.4rem;
        }
    }

    strong {
        font-weight: var(--font-weight-semiBold);
    }

    &.-inclusive {
        span::before {
            background-image: url("../icons/inclusive.svg");
            background-size: 1.4rem 1.1rem;
        }
    }

    &.-exclusive {
        color: var(--color-text-light);

        span::before {
            background-image: url("../icons/exclusive.svg");
            background-size: 1.4rem 1.4rem;
        }
    }
}

.price-plan__foldout {
    &[open] {
        summary {
            span {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    summary {
        &::marker {
            font-size: 0; // hide marker
        }

        &::-webkit-details-marker {
            display: none; // hide marker safari
        }

        span {
            font-size: 2rem;
            color: var(--color-gray-medium);

            &::after {
                content: '';
                display: inline-block;
                margin-left: .6rem;
                background-image: url("../icons/select.svg");
                background-repeat: no-repeat;
                background-size: 1.4rem .8rem;
                width: 1.4rem;
                height: .8rem;
            }
        }

        button {
            pointer-events: none;
            display: block;
            background-color: transparent;
            padding: .3rem 1.8rem;
            border: .1rem solid var(--color-gray);
            border-radius: .4rem;
            font-size: 1.3rem;
            color: var(--color-gray-medium);
            letter-spacing: .04rem;
        }
    }
}

@media (min-width: 720px) {
    .price-plans {
        flex-wrap: nowrap;
        width: calc(100% + 4rem);
        margin-left: -2rem;
    }

    .price-plan {
        padding: 3.7rem 3rem 3.7rem 3.7rem;
        margin: 0 2rem 4rem;
    }
}
