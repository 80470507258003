.credits {
    background-color: var(--color-primary);
    border-radius: .4rem;
    padding: .4rem .8rem;

    font-size: 1.3rem;
    color: var(--color-white);
    line-height: 1.6;
    white-space: nowrap;

    &.-premium {
        background-color: var(--color-orange);
    }

    & + .dropdown.-user {
        margin-left: 2.7rem;
    }
}

.credits__amount {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1.7rem;
    height: 1.6rem;
    min-width: 2.2rem;

    background-color: var(--color-white);
    border-radius: .3rem;
    padding: 0 .6rem;
    box-sizing: border-box;

    font-size: 1.4rem;
    color: var(--color-gray-medium);
    font-style: normal;
    line-height: 1.3;

    &.-infinite {
        font-size: 2.2rem;
        line-height: .8;
    }
}
