.card__empty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 3.3rem 3.5rem;
}

.card__empty__left,
.card__empty__right {
    padding: .5rem 0;
}

.card__empty__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    img {
        display: block;
        width: 5rem;
        height: 5rem;
        border-radius: .2rem;
        margin: 0 2.9rem 1rem 0;
    }

    .title-5, p {
        margin: 0 0 .5rem;
    }

    p {
        color: var(--color-gray);
    }
}
